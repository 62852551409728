import { AlertColor } from '@mui/material'
import React from 'react'

export type SnackbarState = {
  isOpen: boolean
  message: string
  dismissDuration?: number
  severity?: AlertColor
}

export type SnackbarContextType = {
  snackbar: SnackbarState
  openSnackbar: (
    message: string,
    dismissDuration?: number,
    severity?: AlertColor
  ) => void
  closeSnackbar: () => void
}

export const SnackbarContext = React.createContext<
  SnackbarContextType | undefined
>(undefined)
