import { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { UserClaims } from '@okta/okta-auth-js'
import { ExtendedUserClaims } from 'types'

import { oktaLogin, registerOktaId } from '../services/api.service'

export const BE_ACCESS_TOKEN_LOCALSTORAGE_KEY = 'AFLMint.AccessToken'

const mockUserData = {
  sub: 'fakefakefakefake',
  name: 'Samuel L Jackson',
  locale: 'en_US',
  email: 'samtheman@gmail.com',
  preferred_username: 'samtheman',
  given_name: 'Sam',
  family_name: 'Jackson',
  zoneinfo: 'America/Los_Angeles',
  updated_at: 1675997226,
  email_verified: true,
  c_orgsAFLMintConsent: true,
  c_AFLFavouriteTeams: 'Geelong Cats'
}

const useCurrentUser = () => {
  const { authState, oktaAuth } = useOktaAuth()
  const [user, setUser] = useState<ExtendedUserClaims | UserClaims | null>(null)
  const [signedUser, setSignedUser] = useState<boolean>(false)
  const [userNeedsToActivateAccount, setUserNeedsToActivateAccount] =
    useState<boolean>(false)
  const [isDevLoggedIn, setIsDevLoggedIn] = useState<boolean>(
    localStorage.getItem('devIsLoggedIn') === 'true'
  )

  const tools = {
    signUp: () =>
      oktaAuth.signInWithRedirect({
        redirectUri: import.meta.env.VITE_OKTA_REDIRECT_URI_SIGN_UP
      }),
    logIn: () => oktaAuth.signInWithRedirect(),
    logOut: () => oktaAuth.signOut()
  }

  useEffect(() => {
    ;(async () => {
      if (!authState) return
      if (!authState.isAuthenticated) {
        // await oktaAuth.signInWithRedirect()
        if (isDevLoggedIn) {
          setUser(mockUserData)
          console.info('mock user data loaded!')
        }
        setUserNeedsToActivateAccount(true)
      } else {
        const info = await oktaAuth.getUser()
        const result = await registerOktaId(info.sub)
        const backendAccessToken = await oktaLogin(
          authState.idToken?.idToken || ''
        )
        localStorage.setItem(
          BE_ACCESS_TOKEN_LOCALSTORAGE_KEY,
          JSON.stringify(backendAccessToken)
        )
        if (result && backendAccessToken) setUser(info)
        if (!!result && result.message == 'Already exists') {
          setSignedUser(true)
        }
      }
    })()
  }, [
    authState,
    authState?.isAuthenticated,
    oktaAuth,
    isDevLoggedIn,
    signedUser
  ])

  return {
    signedUser,
    user,
    isLogged: authState?.isAuthenticated,
    tools,
    isDevLoggedIn,
    setIsDevLoggedIn,
    userNeedsToActivateAccount
  }
}

export default useCurrentUser
