import { lazy, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { LoginCallback } from '@okta/okta-react'

const SignupPage = lazy(() => import('./pages/auth/Signup'))
const HomePage = lazy(() => import('./pages/main/Home'))
const Thanks = lazy(() => import('./pages/main/Thanks'))
const Terms = lazy(() => import('pages/main/Terms'))
const MintMissionTerms = lazy(() => import('pages/main/AFLMintMissionTerms'))
const Privacy = lazy(() => import('pages/main/Privacy'))
const MintGuide = lazy(() => import('pages/main/MintGuide'))
const GenesisIndex = lazy(() => import('pages/main/Collections/GenesisIndex'))
const Promotion = lazy(() => import('pages/main/Promotion/Promotion'))
const GenesisBallDetails = lazy(
  () => import('pages/main/Collections/GenesisBallDetails')
)
const Marketplace = lazy(() => import('pages/main/Marketplace/Marketplace'))
const RipperSkippers = lazy(() => import('pages/main/Packs/RipperSkippers'))
const PreviousDrops = lazy(() => import('pages/main/Packs/PreviousDropsPage'))
const SingleMoment = lazy(() => import('pages/main/Moments/SingleMoment'))
const GenericMoment = lazy(() => import('pages/main/Moments/GenericMoment'))
const Reward = lazy(() => import('pages/main/Reward/SingleReward'))
const Packs = lazy(() => import('pages/main/Packs'))
const Learn = lazy(() => import('pages/main/Learn'))
const RoadMap = lazy(() => import('pages/main/RoadMap'))
const GenesisBallExchange = lazy(
  () => import('pages/main/Collections/GenesisBallExchange')
)
const Genesis = lazy(() => import('pages/main/Collections/Genesis'))
const Collections = lazy(() => import('pages/main/Collections'))
const ExchangeBall = lazy(() => import('pages/main/Exchange/ExchangeBall'))
const Article = lazy(() => import('pages/main/Article'))
const GetStarted = lazy(() => import('pages/main/GetStarted'))
const TrophyCabinet = lazy(
  () => import('pages/main/TrophyCabinet/TrophyCabinet')
)
const SinglePack = lazy(() => import('pages/main/Pack/SinglePack'))
const OpenPack = lazy(() => import('pages/main/OpenPack/OpenPack'))
const OpenCollectible = lazy(
  () => import('pages/main/OpenPack/OpenCollectible')
)
const OpenCollectiblePack = lazy(
  () => import('pages/main/OpenPack/OpenCollectiblePack')
)
const OpenNFT = lazy(() => import('pages/main/OpenPack/OpenNFT'))
const PageNotFound = lazy(() => import('pages/main/PageNotFound'))
const CollectionMomentsIndex = lazy(
  () => import('pages/main/Packs/CollectionMomentsIndex')
)
const PacksPage = lazy(() => import('pages/main/Packs/PacksPage'))
const CollectionsIndex = lazy(() => import('pages/main/Packs/CollectionsIndex'))
const LandingPage = lazy(() => import('pages/main/Packs/LandingPage'))
const ActivityPage = lazy(() => import('pages/main/Activity/ActivityPage'))
const AccountsPage = lazy(
  () => import('pages/main/Accounts/AccountManagementPage')
)
const Dashboard = lazy(() => import('pages/main/Admin/Dashboard'))
const MarketplaceDashboard = lazy(
  () => import('pages/main/Admin/MarketplaceDashboard')
)
const LoginPage = lazy(() => import('pages/main/LoginPage'))
const MarketplaceBuyPage = lazy(
  () => import('pages/main/Marketplace/MarketplaceBuyPage')
)
const MarketplaceListing = lazy(
  () => import('pages/main/Marketplace/MarketplaceListing')
)
const GenericBallDetails = lazy(
  () => import('pages/main/Collections/GenericBallDetails')
)
const TradeHistory = lazy(() => import('pages/main/Activity/TradeHistory'))
const PromoPackClaimPage = lazy(
  () => import('pages/main/PromoPackClaim/PromoPackClaim')
)
const RookiesExchangePage = lazy(
  () => import('pages/main/TradePage/RookiesExchangePage')
)
const RookiesMomentsIndex = lazy(() => import('pages/main/RookiesMomentsIndex'))
const DuplicateTradePage = lazy(
  () => import('pages/main/TradePage/DuplicatesTradePage')
)
const RipperSkippersTradePage = lazy(
  () => import('pages/main/TradePage/RipperSkippersTradePage')
)

const RoutesConfig = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Routes>
      <Route path="/" element={<HomePage />}></Route>
      <Route path="/)" element={<HomePage />}></Route>
      <Route path="*" element={<PageNotFound />}></Route>
      <Route path="/get-started" element={<GetStarted />}></Route>
      <Route path="/admin/login-page" element={<LoginPage />}></Route>
      <Route path="/starter-pack" element={<PageNotFound />}></Route>
      <Route path="/registration" element={<SignupPage />}></Route>
      <Route path="/login/callback" element={<LoginCallback />}></Route>
      <Route path="/activity/:id" element={<ActivityPage />}></Route>
      <Route path="/account" element={<AccountsPage />}></Route>
      <Route path="/admin/dashboard" element={<Dashboard />}></Route>
      <Route
        path="/admin/marketplace"
        element={<MarketplaceDashboard />}
      ></Route>
      <Route path="/thank-you" element={<Thanks />}></Route>
      <Route path="/privacy-policy" element={<Privacy />}></Route>
      <Route path="/terms-of-use" element={<Terms />}></Route>
      <Route path="/mint-mission-terms" element={<MintMissionTerms />}></Route>
      <Route path="/mint-guide" element={<MintGuide />}></Route>
      <Route path="/collections" element={<Collections />}></Route>
      <Route path="/collections/genesis" element={<Genesis />}></Route>
      <Route path="/genesis-index" element={<GenesisIndex />}></Route>
      <Route
        path="/collections/genesisball-exchange"
        element={<GenesisBallExchange />}
      ></Route>
      <Route
        path="/genesis-ball/:nftId"
        element={<GenesisBallDetails />}
      ></Route>
      <Route
        path="/generic-ball/:templateId"
        element={<GenericBallDetails />}
      ></Route>
      <Route path="/exchange-ball" element={<ExchangeBall />}></Route>
      <Route path="/packs" element={<Packs />}></Route>
      <Route path="/promotion" element={<Promotion />}></Route>
      <Route path="/packs/ripper-skippers" element={<RipperSkippers />}></Route>
      <Route path="/packs/previous-drops" element={<PreviousDrops />}></Route>
      <Route path="/mint-moments" element={<CollectionsIndex />}></Route>
      <Route path="/collections/:_slug" element={<CollectionsIndex />}></Route>
      <Route path="/landing-page/:_slug" element={<LandingPage />}></Route>
      <Route
        path="/relentless"
        element={<Navigate to="/packs/relentless" replace />}
      ></Route>
      <Route
        path="/drop-index/throwbacks"
        element={<Navigate to="/collections/throwbacks" replace />}
      ></Route>
      <Route path="/packs/:slug" element={<PacksPage />}></Route>
      <Route
        path="/momentIndex/:slug"
        element={<CollectionMomentsIndex />}
      ></Route>
      <Route path="/pack/:id" element={<SinglePack />}></Route>
      <Route path="/moment/:momentId" element={<SingleMoment />}></Route>
      <Route path="/moments/:momentId" element={<GenericMoment />}></Route>
      <Route path="/openpack/:id" element={<OpenPack />}></Route>
      <Route
        path="/claim-collectible"
        element={<OpenCollectiblePack />}
      ></Route>
      <Route path="/open-collectible" element={<OpenCollectible />}></Route>
      <Route path="/opennft/:id" element={<OpenNFT />}></Route>
      <Route path="/rewards/:momentId" element={<Reward />}></Route>
      <Route path="/marketplace" element={<Marketplace />}></Route>
      <Route
        path="/marketplace/:selectedCollection"
        element={<Marketplace />}
      ></Route>
      <Route
        path="/marketplace/list-item/:id"
        element={<MarketplaceListing />}
      ></Route>
      <Route
        path="/marketplace/buy/:id"
        element={<MarketplaceBuyPage />}
      ></Route>
      <Route path="/trophy-cabinet" element={<TrophyCabinet />}></Route>
      <Route
        path="/trophy-cabinet/:walletAddress"
        element={<TrophyCabinet />}
      ></Route>
      <Route path="/learn" element={<Learn />}></Route>
      <Route path="/roadmap" element={<RoadMap />}></Route>
      <Route path="/learn/:articleId" element={<Article />}></Route>
      <Route path="/trade-history" element={<TradeHistory />}></Route>
      <Route
        path="/trade-history/:walletAddress"
        element={<TradeHistory />}
      ></Route>
      <Route
        path="/duplicates-exchange"
        element={<DuplicateTradePage />}
      ></Route>
      <Route
        path="/duplicates-exchange/:walletAddress"
        element={<DuplicateTradePage />}
      ></Route>
      <Route
        path="/ripper-skipper-exchange/:momentId"
        element={<RipperSkippersTradePage />}
      ></Route>
      <Route
        path="/rookies-exchange/:momentId"
        element={<RookiesExchangePage />}
      ></Route>
      <Route path="/mint-rookies" element={<RookiesMomentsIndex />}></Route>
      <Route path="/promo-pack-claim" element={<PromoPackClaimPage />}></Route>
      <Route
        path="/promo-pack-claim/:promoCode"
        element={<PromoPackClaimPage />}
      ></Route>
    </Routes>
  </Suspense>
)

export default RoutesConfig
