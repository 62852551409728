import React from 'react'
import { SvgIcon } from '@mui/material'

const ExternalLink = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={props.fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M21.65 12.525V19.275C21.65 19.925 21.4167 20.4792 20.95 20.9375C20.4833 21.3958 19.925 21.625 19.275 21.625H4.725C4.075 21.625 3.52083 21.3958 3.0625 20.9375C2.60417 20.4792 2.375 19.925 2.375 19.275V4.72501C2.375 4.07501 2.60417 3.51667 3.0625 3.05001C3.52083 2.58334 4.075 2.35001 4.725 2.35001H11.475V4.72501H4.725V19.275H19.275V12.525H21.65ZM10.125 15.525L8.5 13.875L17.65 4.72501H12.975V2.35001H21.65V11.025H19.275V6.37501L10.125 15.525Z"
          fill={props.fill || '#D3D3D3'}
        />
      </svg>
    </SvgIcon>
  )
}

export default ExternalLink
