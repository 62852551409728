import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ISiteSetting } from 'types'
import { getSiteSettings } from 'services/api.service'
import { disableDevTools } from 'utils/disableDevTools'
import RoutesConfig from './RoutesConfig'
import MaintenancePage from 'pages/main/MaintenancePage'
import BackToTop from 'components/Button/BackToTop'
import Nav from 'layout/Nav'

const theme = createTheme()

const App = () => {
  const [maintenanceMode, setMaintenanceMode] = useState<boolean | undefined>(
    false
  )
  const [loading, setLoading] = useState(true)

  const location = useLocation()
  const adminPermission = localStorage.getItem(
    'adminPermissionAFLAdminDevTester'
  )

  const fetchSiteSettings = async () => {
    try {
      const siteSettings: ISiteSetting = await getSiteSettings()
      setMaintenanceMode(siteSettings.siteIsInMaintenanceMode ?? false)
      setLoading(false)
    } catch (error) {
      console.error('Failed to fetch site settings:', error)
      setMaintenanceMode(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchSiteSettings()
  }, [])

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const adminPermission = query.get('AFLAdminDevTester')
    if (adminPermission === 'true') {
      localStorage.setItem('adminPermissionAFLAdminDevTester', 'true')
    }
  }, [location.search])

  if (loading) return <></>

  if (import.meta.env.VITE_ENVIRONMENT === 'production') {
    disableDevTools()
  }

  if (maintenanceMode && adminPermission !== 'true') {
    return <MaintenancePage />
  } else {
    return (
      <ThemeProvider theme={theme}>
        {location.pathname !== '/get-started' && <Nav />}
        {<BackToTop />}
        <RoutesConfig />
      </ThemeProvider>
    )
  }
}

export default App
