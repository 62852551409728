import { SvgIcon } from '@mui/material'

const ChevronBtn = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="13"
        height="20"
        viewBox="0 -7 10 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M3 0.100586L0.171573 2.92901L7.24264 10.0001L0.171573 17.0711L3 19.8996L12.8995 10.0001L3 0.100586Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}

export default ChevronBtn
