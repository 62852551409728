import React from 'react'
import { Grid } from '@mui/material'
import discord from '/assets/Icon/Discord.svg'
import twitter from '/assets/Icon/Twitter.svg'
import instagram from '/assets/Icon/Instagram.svg'
import facebook from '/assets/Icon/Facebook.svg'
import youtube from '/assets/Icon/Youtube.svg'

const Socials = () => {
  return (
    <div className="flex gap-6 justify-center px-2 pb-1 mx-auto">
      <div>
        <a
          href="https://discord.com/invite/aflmintofficial"
          target="_blank"
          rel="noreferrer"
        >
          <img src={discord} alt="" className="social-icon" />
        </a>
      </div>
      <div>
        <a href="https://twitter.com/aflmint" target="_blank" rel="noreferrer">
          <img src={twitter} alt="" className="social-icon" />
        </a>
      </div>
      <div>
        <a
          href="https://www.instagram.com/aflmint/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="" className="social-icon" />
        </a>
      </div>
      <div>
        <a
          href="https://www.youtube.com/channel/UCT1E0Rmm6_DY99dRTFI1hyQ"
          target="_blank"
          rel="noreferrer"
        >
          <img src={youtube} alt="" className="social-icon" />
        </a>
      </div>
      <div>
        <a href="https://facebook.com/aflmint" target="_blank" rel="noreferrer">
          <img src={facebook} alt="" className="social-icon" />
        </a>
      </div>
    </div>
  )
}

export default Socials
