import { useState } from 'react'
import { motion } from 'framer-motion'
import { Add } from '@mui/icons-material'
import { ExpandMore } from '@mui/icons-material'

export const Toggle = ({
  children,
  title,
  initialState,
  width
}: {
  children: React.ReactNode
  title: string
  initialState?: boolean
  width?: string
}) => {
  const [toggle, setToggle] = useState(initialState || false)

  return (
    <motion.div
      layout
      className="w-1/2 text-left question"
      style={{ width: width }}
      onClick={() => setToggle(!toggle)}
    >
      <motion.div className="flex justify-between">
        <motion.h4 layout className="text-xl font-bold">
          {title}
        </motion.h4>
        <motion.div layout>
          {toggle ? (
            <Add
              style={{
                transition: 'transform 0.3s',
                transform: `rotate(45deg)`,
                color: '#7E7E7E'
              }}
            />
          ) : (
            <Add
              style={{
                transition: 'transform 0.3s',
                transform: `rotate(0deg)`,
                color: '#7E7E7E'
              }}
            />
          )}
        </motion.div>
      </motion.div>
      {toggle ? children : ''}
      <div className="faq-line"></div>
    </motion.div>
  )
}

export const Toggle2 = ({
  children,
  title,
  initialState,
  width
}: {
  children: React.ReactNode
  title: string
  initialState?: boolean
  width?: string
}) => {
  const [toggle, setToggle] = useState(initialState || false)

  return (
    <motion.div
      layout
      className="w-full text-left lg:w-4/5 question"
      style={{ width: width }}
      onClick={() => setToggle(!toggle)}
    >
      <motion.div className="flex justify-between">
        <motion.h4 layout className="text-lg font-bold">
          {title}
        </motion.h4>
        <motion.div layout>
          {toggle ? (
            <Add
              style={{
                transition: 'transform 0.3s',
                transform: `rotate(45deg)`,
                color: '#7E7E7E'
              }}
            />
          ) : (
            <Add
              style={{
                transition: 'transform 0.3s',
                transform: `rotate(0deg)`,
                color: '#7E7E7E'
              }}
            />
          )}
        </motion.div>
      </motion.div>
      {toggle ? children : ''}
      <div className="faq-line"></div>
    </motion.div>
  )
}

export const Toggle3 = ({
  children,
  title,
  initialState,
  width
}: {
  children: React.ReactNode
  title: string
  initialState?: boolean
  width?: string
}) => {
  const [toggle, setToggle] = useState(initialState || false)

  return (
    <motion.div
      layout
      className="w-full text-left lg:w-4/5 question"
      style={{ width: width }}
      onClick={() => setToggle(!toggle)}
    >
      <motion.div className="flex justify-between">
        <motion.h4 layout className="font-bold tracking-wide">
          {title}
        </motion.h4>
        <motion.div layout>
          {toggle ? (
            <Add
              style={{
                transition: 'transform 0.3s',
                transform: `rotate(45deg)`,
                color: '#7E7E7E'
              }}
            />
          ) : (
            <Add
              style={{
                transition: 'transform 0.3s',
                transform: `rotate(0deg)`,
                color: '#7E7E7E'
              }}
            />
          )}
        </motion.div>
      </motion.div>
      {toggle ? children : ''}
      <div className="faq-line"></div>
    </motion.div>
  )
}

export const Toggle4 = ({
  children,
  title,
  initialState,
  width
}: {
  children: React.ReactNode
  title: string
  initialState?: boolean
  width?: string
}) => {
  const [toggle, setToggle] = useState(initialState || false)

  return (
    <motion.div
      layout
      className="w-full text-left lg:w-4/5 question"
      style={{ width: width }}
      onClick={() => setToggle(!toggle)}
    >
      <motion.div className="flex justify-between">
        <motion.h4
          layout
          className="font-podium96 text-xs tracking-widest uppercase"
        >
          {title}
        </motion.h4>
        <motion.div layout>
          {toggle ? (
            <Add
              style={{
                transition: 'transform 0.3s',
                transform: `rotate(45deg)`,
                color: '#7E7E7E'
              }}
            />
          ) : (
            <Add
              style={{
                transition: 'transform 0.3s',
                transform: `rotate(0deg)`,
                color: '#7E7E7E'
              }}
            />
          )}
        </motion.div>
      </motion.div>
      {toggle ? children : ''}
      <div className="faq-line"></div>
    </motion.div>
  )
}

export const Toggle5 = ({
  children,
  title,
  initialState,
  width
}: {
  children: React.ReactNode
  title: string
  initialState?: boolean
  width?: string
}) => {
  const [toggle, setToggle] = useState(initialState || false)

  return (
    <motion.div
      layout
      className="w-full text-left  question"
      style={{ width: width }}
      onClick={() => setToggle(!toggle)}
    >
      <div className="faq-line" style={{ margin: '0px 0px 14px' }}></div>
      <motion.div className="flex justify-between">
        <motion.h4 layout className="text-sm text-white pt-[2px]">
          {title}
        </motion.h4>
        <motion.div layout>
          {toggle ? (
            <Add
              style={{
                transition: 'transform 0.3s',
                transform: `rotate(45deg)`,
                color: '#7E7E7E'
              }}
            />
          ) : (
            <Add
              style={{
                transition: 'transform 0.3s',
                transform: `rotate(0deg)`,
                color: '#7E7E7E'
              }}
            />
          )}
        </motion.div>
      </motion.div>
      {toggle ? children : ''}
      <div className="faq-line" style={{ margin: '14px 0px 14px' }}></div>
    </motion.div>
  )
}

export const Toggle6 = ({
  children,
  title,
  initialState,
  width,
  className
}: {
  children: React.ReactNode
  title: string
  initialState?: boolean
  width?: string
  className?: string
}) => {
  const [toggle, setToggle] = useState(initialState || false)

  return (
    <motion.div
      layout
      className={`w-full text-left question ripper-skippers-moments`}
      style={{ width: width }}
    >
      <motion.div
        className="flex justify-between py-2"
        onClick={() => setToggle(!toggle)}
      >
        <motion.h4 layout className="font-bold">
          {title}
        </motion.h4>
        <motion.div layout>
          {toggle ? (
            <ExpandMore
              style={{
                transition: 'transform 0.3s',
                transform: `rotate(-180deg)`,
                marginRight: `0px`
              }}
            />
          ) : (
            <ExpandMore
              style={{
                transition: 'transform 0.3s',
                transform: `rotate(0deg)`,
                marginRight: `0px`
              }}
            />
          )}
        </motion.div>
      </motion.div>
      <motion.div
        className={`overflow-auto w-full max-h-[360px] text-left question ripper-skippers-moments ${className}`}
      >
        {toggle ? children : ''}
      </motion.div>
      <div className="faq-line" style={{ margin: '16px 0rem' }}></div>
    </motion.div>
  )
}
