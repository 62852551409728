import { Grid } from '@mui/material'
import CustomButton from 'components/Button/CustomButton'
import discord from '/assets/Icon/Discord.svg'

export interface ZendeskSectionProps {
  learnPage?: boolean
}

const ZendeskSection = ({ learnPage = false }: ZendeskSectionProps) => {
  return (
    <Grid
      container
      className={`justify-center items-center pb-20 text-white bg-black lg:pb-32 ${
        learnPage && 'pt-14 lg:pt-20'
      }`}
    >
      <Grid item className="text-center">
        <Grid item xs={12} py={2}>
          <p className="font-podium49 text-2xl tracking-wider uppercase">
            Can&apos;t find what you&apos;re looking for?
          </p>
        </Grid>
        {learnPage && (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://discord.com/invite/aflmintofficial"
          >
            <Grid
              item
              className="flex
           justify-center items-center pb-3"
            >
              <Grid item>
                <img src={discord} alt="" className="!h-8 social-icon" />
              </Grid>
              <p className="pl-3 text-xl">Join the AFL Mint Discord</p>
            </Grid>
          </a>
        )}
        <Grid item container className="justify-center" xs={12} pt={1}>
          <a
            href="https://support.aflmint.com.au/hc/en-gb/requests/new "
            target="_blank"
            rel="noreferrer"
          >
            <CustomButton
              type="button"
              model="primary"
              variant="contained"
              label="Send us a message"
            />
          </a>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ZendeskSection
