export const CREATE_COLLECTION = `
  import AFLNFT from 0xAFLContracts
  import NonFungibleToken from 0xNFT
  import FungibleToken from 0xFungibleToken
  import MetadataViews from 0xMetadataViews
  import USDCFlow from 0xUSDCFlow

  transaction {
    prepare(signer: auth(BorrowValue, Storage, LoadValue, SaveValue, IssueStorageCapabilityController, PublishCapability, Capabilities, UnpublishCapability) &Account) {

      // Create a new empty collection if one doesn't exist
      if signer.storage.borrow<&AFLNFT.Collection>(from: AFLNFT.CollectionStoragePath) == nil {
        let collection: @{NonFungibleToken.Collection} <- AFLNFT.createEmptyCollection(nftType: Type<@AFLNFT.Collection>())
        signer.storage.save<@{NonFungibleToken.Collection}>(<-collection, to: AFLNFT.CollectionStoragePath)

        let collectionCap: Capability<&{AFLNFT.AFLNFTCollectionPublic}> = signer.capabilities.storage.issue<&{AFLNFT.AFLNFTCollectionPublic}>(AFLNFT.CollectionStoragePath)

        signer.capabilities.unpublish(AFLNFT.CollectionPublicPath)
        signer.capabilities.publish(collectionCap, at: AFLNFT.CollectionPublicPath)
      }

      // Create empty USDCFlow Vault and setup capabilities if they doesn't already exist
      if signer.storage.borrow<&USDCFlow.Vault>(from: USDCFlow.VaultStoragePath) == nil {
          signer.storage.save(
            <-USDCFlow.createEmptyVault(vaultType: Type<@USDCFlow.Vault>()),
            to: USDCFlow.VaultStoragePath
          )

          // Create a public capability to the Vault that only exposes
          // the deposit function through the Receiver interface
          let receiver = signer.capabilities.storage.issue<&USDCFlow.Vault>(
              USDCFlow.VaultStoragePath
          )
          signer.capabilities.publish(receiver, at: USDCFlow.ReceiverPublicPath)

          // Create a public capability to the Vault that only exposes
          // the balance field through the Balance interface
          signer.capabilities.publish(receiver, at: USDCFlow.VaultPublicPath)
        }
  }
}
`
