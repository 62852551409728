import { useState, useEffect } from 'react'
import { useWallets } from 'providers/WalletsProvider'
import { ISiteSettings } from '../types'
import { getSiteSettings } from 'services/api.service'

const ADMIN_PERMISSION_KEY = 'adminPermissionAFLAdminDevTester'

export const useSiteSettings = () => {
  const { flowUser } = useWallets()
  const [siteSettings, setSiteSettings] = useState<ISiteSettings | null>(null)

  useEffect(() => {
    const fetchSiteSettings = async () => {
      try {
        const fetchedSettings: ISiteSettings = await getSiteSettings()
        setSiteSettings(fetchedSettings)
      } catch (error) {
        console.error('Failed to fetch site settings:', error)
        setSiteSettings(null)
      }
    }

    fetchSiteSettings()
  }, [])

  const adminPermission = localStorage.getItem(ADMIN_PERMISSION_KEY)

  // Feature Flags (from environment variables)
  const featureFlags = {
    USE_MAGIC_WALLET: import.meta.env.VITE_USE_MAGICWALLET === 'true',
    MARKETPLACE_ACTIVE: import.meta.env.VITE_ENABLE_MARKETPLACE === 'true',
    PRIZE_DRAW_ACTIVE: import.meta.env.VITE_ENABLE_PRIZE_DRAW === 'true',
    MARKETPLACE_PRODUCTINFO_POPUP_ACTIVE:
      import.meta.env.VITE_ENABLE_MARKETPLACE_INFO_POPUP === 'true',
    MARKETPLACE_FEE: parseFloat(import.meta.env.VITE_MARKETPLACE_FEE ?? '0.08'),
    DUPLICATES_SWAP_ENABLED:
      import.meta.env.VITE_ENABLE_DUPLICATES_SWAP === 'true'
  }

  // Dynamic Site Settings (from API)
  const dynamicSettings = {
    isBanxaEnabled:
      siteSettings?.filter((setting) => setting.key === 'IS_BANXA_LIVE')[0]
        ?.value === true || adminPermission === 'true',
    isBanxaTopUpEnabled:
      siteSettings?.filter(
        (setting) => setting.key === 'IS_BANXA_TOP_UP_ENABLED'
      )[0]?.value === true,
    isMoonpayEnabled:
      siteSettings?.filter((setting) => setting.key === 'IS_MOONPAY_LIVE')[0]
        ?.value === true || adminPermission === 'true',
    isMoonpayTopUpEnabled:
      siteSettings?.filter(
        (setting) => setting.key === 'IS_MOONPAY_TOP_UP_ENABLED'
      )[0]?.value === true
  }

  // Derived Settings
  const derivedSettings = {
    isCardPaymentsEnabled:
      dynamicSettings.isBanxaEnabled || dynamicSettings.isMoonpayEnabled,
    isTopUpAvailable:
      dynamicSettings.isBanxaTopUpEnabled ||
      dynamicSettings.isMoonpayTopUpEnabled
  }

  return {
    ...featureFlags,
    ...dynamicSettings,
    ...derivedSettings
  }
}
