export const BUY_HASHED_PACK = `import AFLNFT from 0xAFLContracts
import AFLPack from 0xAFLContracts
import FungibleToken from 0xFungibleToken
import USDCFlow from 0xUSDCFlow

transaction(packHash: String, packTemplateId: UInt64, receiptAddress: Address) {
  let funds : @{FungibleToken.Vault}

  prepare(buyerAccount: auth(BorrowValue, FungibleToken.Withdraw) &Account) {

      let packTemplateData: AFLNFT.Template = AFLNFT.getTemplateById(templateId: packTemplateId)
      let metadata: {String: AnyStruct} = packTemplateData.getImmutableData()
      let priceFromMetadata = metadata["price"]
      let priceString = priceFromMetadata as! String?
      let price: UFix64? = UFix64.fromString(priceString!)

      let vaultRef: auth(BorrowValue, FungibleToken.Withdraw) &USDCFlow.Vault = buyerAccount.storage.borrow<auth(BorrowValue, FungibleToken.Withdraw) &USDCFlow.Vault>(from: USDCFlow.VaultStoragePath)
              ?? panic("could not borrow vault")
      self.funds <- vaultRef.withdraw(amount: price!)
  }

  execute {
      AFLPack.buyHashedPack(
            packHash: packHash,
            receiptAddress: receiptAddress,
            packTemplateId: packTemplateId,
            flowPayment: <- self.funds
      )
  }
}`
