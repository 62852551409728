import React, { useEffect } from 'react'
import CustomButton from 'components/Button/CustomButton'
import card from '/assets/Icon/credit-card.svg'
import mastercard from '/assets/Icon/mastercard.svg'
import visa from '/assets/Icon/visa.svg'

interface CreditCardPaymentCardProps {
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>
}

function CreditCardPaymentCard({ setActiveStep }: CreditCardPaymentCardProps) {
  return (
    <div className="flex flex-col gap-2 justify-between p-4 w-full bg-card rounded-[3px]">
      <div className="flex flex-col gap-2 w-full">
        <div className="flex gap-3 justify-between items-start w-full">
          <div className="flex flex-col gap-2 justify-center items-start w-full">
            <div className="flex justify-between items-center w-full font-bold">
              <div className="flex gap-2 items-center">
                <img src={card} alt={`Wallet icon`} className="w-6 h-6" />
                <div className="text-white">Credit/debit card</div>
              </div>
              <div className="flex gap-1">
                <img src={mastercard} alt="" />
                <img src={visa} alt="" />
              </div>
            </div>
            <p className="text-xs">5-10 mins • 3-4% fee</p>
          </div>
        </div>

        <CustomButton
          label="Continue"
          model="primary"
          variant="contained"
          hasEndIcon={true}
          onClick={() => setActiveStep && setActiveStep(2)}
        />
      </div>
    </div>
  )
}
export default CreditCardPaymentCard
