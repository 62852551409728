import ReactGA from 'react-ga4'
import { Pack } from 'types'

const packToItems = (pack: Pack) => [
  {
    item_id: pack.templateId,
    item_name: pack.immutableData.collectionName,
    item_category: pack.immutableData.collectionName,
    price: pack.immutableData.price,
    quantity: 1
  }
]

const logIn = (method: string) => {
  ReactGA.event('login', {
    method: method
  })
}

const selectItem = async (pack: Pack) => {
  ReactGA.event('select_item', { items: packToItems(pack) })
}

const viewItem = async (pack: Pack) => {
  ReactGA.event('view_item', {
    currency: 'USD',
    value: pack.immutableData.price,
    items: packToItems(pack)
  })
}

const beginCheckout = async (pack: Pack, payment_type: 'USDC' | 'MoonPay') => {
  ReactGA.event('begin_checkout', {
    currency: 'USD',

    value: pack.immutableData.price,
    items: packToItems(pack)
  })
  ReactGA.event('add_payment_info', {
    currency: 'USD',
    value: pack.immutableData.price,
    payment_type: payment_type,
    items: packToItems(pack)
  })
}

const buyPack = async (pack: Pack, transactionId: string) => {
  ReactGA.event('purchase', {
    currency: 'USD',
    transaction_id: transactionId,
    value: pack.immutableData.price,
    items: packToItems(pack)
  })
}

const gaEvents = {
  beginCheckout,
  buyPack,
  logIn,
  selectItem,
  viewItem
}

export { gaEvents }
