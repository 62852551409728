// disableDevTools.ts
export function disableDevTools() {
  // Disable right-click
  document.addEventListener('contextmenu', (e) => e.preventDefault())

  function ctrlShiftKey(e: KeyboardEvent, keyCode: string) {
    return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0)
  }

  function appleShiftKey(e: KeyboardEvent, keyCode: string) {
    return e.metaKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0)
  }

  document.onkeydown = (e: KeyboardEvent) => {
    // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
    if (
      e.keyCode === 123 ||
      ctrlShiftKey(e, 'I') ||
      ctrlShiftKey(e, 'J') ||
      ctrlShiftKey(e, 'C') ||
      ctrlShiftKey(e, 'D') ||
      appleShiftKey(e, 'I') ||
      appleShiftKey(e, 'J') ||
      appleShiftKey(e, 'C') ||
      appleShiftKey(e, 'D') ||
      (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
    )
      return false
  }
}
