import { useEffect, useState } from 'react'
import { query, send, tx } from '@onflow/fcl'
import * as fcl from '@blocto/fcl'
import { CHECK_COLLECTION } from '../flow/scripts/checkCollection.script'
import { CREATE_COLLECTION } from '../flow/transactions/createCollection.tx'
import { useTransactions } from 'providers/TransactionsProvider'
import { DESTROY_COLLECTION } from 'flow/transactions/destroyCollection.tx'
import { useWallets } from 'providers/WalletsProvider'
import { waitForSealed } from 'utils/flow'

export default function useCollection(user: any) {
  const { usingMagic, magic } = useWallets()
  const [loading, setLoading] = useState(true)
  const [collection, setCollection] = useState<boolean | undefined>()
  const { addTx } = useTransactions()

  const checkCollection = async () => {
    try {
      const res = await query({
        cadence: CHECK_COLLECTION,
        args: (arg: any, t: any) => [arg(user.addr, t.Address)]
      })
      setCollection(res)
      setLoading(false)
    } catch (e) {
      console.error(e)
      // add papertrail(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!user?.addr) {
      setCollection(false)
      setLoading(false)
      return
    }

    checkCollection()
  }, [user])

  const createCollection = async (authFunc?: any) => {
    if (!authFunc) {
      if (usingMagic && magic.flow.authorization) {
        authFunc = magic.flow.authorization
      } else {
        authFunc = fcl.authz
      }
    }
    try {
      setLoading(true)
      const res = await send([
        fcl.transaction(CREATE_COLLECTION),
        fcl.payer(authFunc),
        fcl.proposer(authFunc),
        fcl.authorizations([authFunc]),
        fcl.limit(9999)
      ])
      if (!usingMagic) {
        addTx(res, 'Trophy Cabinet Activation in progress')
      }
      // await tx(res).onceSealed()
      await waitForSealed(res.transactionId)
      checkCollection()
      setLoading(false)
    } catch (e) {
      console.error(e)
      // add papertrail(e)
      setLoading(false)
    }
  }

  const destroyCollection = async (authFunc?: any) => {
    if (!authFunc) {
      if (usingMagic && magic.flow.authorization) {
        authFunc = magic.flow.authorization
      } else {
        authFunc = fcl.authz
      }
    }
    try {
      setLoading(true)
      const res = await send([
        fcl.transaction(DESTROY_COLLECTION),
        fcl.payer(authFunc),
        fcl.proposer(authFunc),
        fcl.authorizations([authFunc]),
        fcl.limit(1000)
      ])
      addTx(res)
      // await tx(res).onceSealed()
      await waitForSealed(res.transactionId)
      setCollection(true)
      setLoading(false)
    } catch (e) {
      console.error(e)
      // add papertrail(e)
      setLoading(false)
    }
  }

  return {
    loading,
    collection,
    createCollection,
    destroyCollection
  }
}
