import React from 'react'

const Coach = ({ children }: { children: React.ReactNode | string }) => {
  return (
    <div className="relative p-4 min-w-[250px] max-w-[250px] bg-blue2 rounded-[3px] shadow-[0_2px_3px_rgba(0,0,0,0.3)]">
      <div className="coach-arrow"></div>
      <div>{children}</div>
    </div>
  )
}

export default Coach
