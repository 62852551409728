import React from 'react'
import { Grid } from '@mui/material'
import CountDown, { CountdownRenderProps, zeroPad } from 'react-countdown'
import RotatingBanner from 'components/Banner/RotatingBanner'

export interface CountdownTimerProps {
  countdownDate: string
  countdownLocation: 'pack' | 'banner' | 'hero' | 'maintenance'
}

export function PadMaintenance(props: any) {
  return (
    <div className="flex z-10 justify-center items-center w-full ">
      <Grid className="flex mx-8 text-center uppercase">
        <div className="flex justify-center items-center w-[70px]">
          <div className="flex flex-col justify-center items-center py-[6px] px-[10px] w-[60px] bg-black1 rounded-md">
            <p className="text-xl">{zeroPad(props.days)}</p>
            <p className="text-[12px] font-bold tracking-widest leading-[18px] text-center">
              DAYS
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center w-[70px]">
          <div className="flex flex-col justify-center items-center py-[6px] px-[10px] w-[60px] bg-black1 rounded-md">
            <p className="text-xl">{zeroPad(props.hours)}</p>
            <p className="text-[12px] font-bold tracking-widest leading-[18px] text-center">
              HRS
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center w-[70px]">
          <div className="flex flex-col justify-center items-center py-[6px] px-[10px] w-[60px] bg-black1 rounded-md">
            <p className="text-xl">{zeroPad(props.minutes)}</p>
            <p className="text-[12px] font-bold tracking-widest leading-[18px] text-center">
              MINS
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center w-[70px]">
          <div className="flex flex-col justify-center items-center py-[6px] px-[10px] w-[60px] bg-black1 rounded-md">
            <p className="text-xl">{zeroPad(props.seconds)}</p>
            <p className="text-[12px] font-bold tracking-widest leading-[18px] text-center">
              SEC
            </p>
          </div>
        </div>
      </Grid>
    </div>
  )
}
export function PadBanner(props: any) {
  return (
    <div className="flex z-10 justify-center items-center w-full ">
      <Grid className="flex mx-8 text-center uppercase">
        <div className="flex justify-center items-center w-[50px]">
          <div className="flex flex-col justify-center items-center py-[6px] px-[10px] w-[42px] bg-black1 rounded-md">
            <p className="">{zeroPad(props.days)}</p>
            <p className="text-[8px] font-bold tracking-widest leading-[10px] text-center">
              DAYS
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center w-[50px]">
          <div className="flex flex-col justify-center items-center py-[6px] px-[10px] w-[42px] bg-black1 rounded-md">
            <p className="">{zeroPad(props.hours)}</p>
            <p className="text-[8px] font-bold tracking-widest leading-[10px] text-center">
              HRS
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center w-[50px]">
          <div className="flex flex-col justify-center items-center py-[6px] px-[10px] w-[42px] bg-black1 rounded-md">
            <p className="">{zeroPad(props.minutes)}</p>
            <p className="text-[8px] font-bold tracking-widest leading-[10px] text-center">
              MINS
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center w-[50px]">
          <div className="flex flex-col justify-center items-center py-[6px] px-[10px] w-[42px] bg-black1 rounded-md">
            <p className="">{zeroPad(props.seconds)}</p>
            <p className="text-[8px] font-bold tracking-widest leading-[10px] text-center">
              SEC
            </p>
          </div>
        </div>
      </Grid>
    </div>
  )
}
export function PadPack(props: any) {
  return (
    <Grid className="flex py-[12px] px-[24px] text-center uppercase bg-black2 rounded-[3px]">
      <div className="flex flex-col justify-center w-[26px]">
        <p className="hero-countdown-text-pack">{zeroPad(props.days)}</p>
      </div>
      <div className="">
        <p className="hero-countdown-text-pack">:</p>
      </div>
      <div className="flex flex-col justify-center w-[26px]">
        <p className="hero-countdown-text-pack">{zeroPad(props.hours)}</p>
      </div>
      <div className="">
        <p className="hero-countdown-text-pack">:</p>
      </div>
      <div className="flex flex-col justify-center w-[26px]">
        <p className="hero-countdown-text-pack">{zeroPad(props.minutes)}</p>
      </div>
      <div className="">
        <p className="hero-countdown-text-pack">:</p>
      </div>
      <div className="flex flex-col justify-center w-[26px]">
        <p className="hero-countdown-text-pack">{zeroPad(props.seconds)}</p>
      </div>
    </Grid>
  )
}
export function Pad(props: any) {
  return (
    <Grid className="flex py-[16px] px-[24px] text-center uppercase bg-black2 rounded-[3px]">
      <div className="flex flex-col justify-center w-[26px]">
        <p className="hero-countdown-text-pack">{zeroPad(props.days)}</p>
      </div>
      <div className="">
        <p className="hero-countdown-text-pack">:</p>
      </div>
      <div className="flex flex-col justify-center w-[26px]">
        <p className="hero-countdown-text-pack">{zeroPad(props.hours)}</p>
      </div>
      <div className="">
        <p className="hero-countdown-text-pack">:</p>
      </div>
      <div className="flex flex-col justify-center w-[26px]">
        <p className="hero-countdown-text-pack">{zeroPad(props.minutes)}</p>
      </div>
      <div className="">
        <p className="hero-countdown-text-pack">:</p>
      </div>
      <div className="flex flex-col justify-center w-[26px]">
        <p className="hero-countdown-text-pack">{zeroPad(props.seconds)}</p>
      </div>
    </Grid>
  )
}

const CountdownTimer = ({
  countdownDate,
  countdownLocation = 'hero'
}: CountdownTimerProps) => {
  const CountdownFinishedBanner = ({
    days,
    hours,
    minutes,
    seconds,
    completed
  }: CountdownRenderProps): React.ReactNode => {
    return (
      <div className="flex flex-col justify-center items-center px-3">
        {completed ? (
          <div>{countdownLocation === 'banner' && <RotatingBanner />}</div>
        ) : (
          <div>
            {countdownLocation === 'maintenance' && (
              <PadMaintenance
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
              />
            )}
            {countdownLocation === 'banner' && (
              <PadBanner
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
              />
            )}
            {countdownLocation === 'pack' && (
              <PadPack
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
              />
            )}
            {countdownLocation === 'hero' && (
              <Pad
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
              />
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      <CountDown date={countdownDate} renderer={CountdownFinishedBanner} />
    </div>
  )
}

export default CountdownTimer
