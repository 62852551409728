import React from 'react'
import information from '/assets/Icon/information.svg'
import { Alert, Collapse } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom'
import ChevronBtn from 'components/Button/Chevron'

export interface LiveBannerProps {
  bannerName: string // used as localstorage key
  icon?: string
  notification: string | React.ReactNode
  children?: React.ReactNode
  className?: string
  ctaLink?: string
  isBannerOpen?: boolean
  setIsBannerOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const LiveBanner = ({
  icon,
  notification,
  className,
  bannerName,
  ctaLink,
  isBannerOpen = false,
  setIsBannerOpen
}: LiveBannerProps) => {
  return (
    <Collapse
      in={isBannerOpen}
      className={className}
      sx={{ width: '100%', position: 'relative' }}
    >
      <Alert
        icon={
          icon ? (
            <img
              src={information}
              alt="information icon"
              className="self-start mr-2 !min-w-[30px] !min-h-[30px] sm:mr-4"
            />
          ) : (
            false
          )
        }
        action={
          <IconButton
            aria-label="close"
            sx={{ color: '#000', position: 'absolute', right: '8px' }}
            size="small"
            onClick={() => {
              localStorage.setItem(`bannerDismissed-${bannerName}`, 'true')
              setIsBannerOpen && setIsBannerOpen(false)
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{
          background: 'linear-gradient(136deg,#C2A3EF 0.52%,#D2E9F5 100%)',
          color: '#000000',
          width: '100%',
          borderRadius: '0px',
          font: 'PODIUMSharp-49',
          fontWeight: 'bold',
          height: '50px',
          '& .MuiAlert-message': {
            width: '100%',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        }}
      >
        <div className="flex justify-center items-center w-full">
          {ctaLink ? (
            <>
              <Link
                to={ctaLink}
                className="font-podium49 font-bold tracking-[0.16px] !leading-none text-black uppercase md:text-base"
              >
                {notification}
              </Link>
              <ChevronBtn sx={{ margin: '0px 0px 6px 4px' }} />
            </>
          ) : (
            <>
              <div className="font-podium49 font-bold tracking-[0.16px] !leading-none text-black uppercase md:text-base">
                {notification}
              </div>
              <ChevronBtn sx={{ margin: '0px 0px 6px 4px' }} />
            </>
          )}
        </div>
      </Alert>
    </Collapse>
  )
}

export default LiveBanner
