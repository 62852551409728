import React from 'react'
import { SvgIcon } from '@mui/material'

const MenuIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 9V11H3V9H22ZM16 6V4L3 4V6L16 6ZM16 21V19H3V21H16ZM22 16V14L3 14V16H22Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  )
}

export default MenuIcon
