import React from 'react'

const RotatingBanner = () => {
  return (
    <div className="fixed bottom-0 z-40 h-[70px] bg-gradient1 rotating-banner">
      <div className="flex items-center h-[70px] rotating-banner-content">
        <p className="banner-text rotating-first">Relentless</p>
        <p className="banner-text">DROP LIVE</p>
        <p className="banner-text">Relentless</p>
        <p className="banner-text">DROP LIVE</p>
        <p className="banner-text">Relentless</p>
        <p className="banner-text">DROP LIVE</p>
        <p className="banner-text">Relentless</p>
        <p className="banner-text">DROP LIVE</p>
        <p className="banner-text">Relentless</p>
        <p className="banner-text">DROP LIVE</p>
        <p className="banner-text">Relentless</p>
        <p className="banner-text">DROP LIVE</p>
        <p className="banner-text">Relentless</p>
        <p className="banner-text">DROP LIVE</p>
        <p className="banner-text">Relentless</p>
        <p className="banner-text">DROP LIVE</p>
      </div>
    </div>
  )
}

export default RotatingBanner
