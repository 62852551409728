import React, { useContext } from 'react'
import { SnackbarContext, SnackbarContextType } from 'providers/SnackbarContext'

export function useSnackbar(): SnackbarContextType {
  const context = useContext(SnackbarContext)
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider')
  }
  return context
}
