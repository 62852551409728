import { useWallets } from 'providers/WalletsProvider'
import { useAuth } from 'providers/AuthProvider'
import { Button, Drawer, Grid, Modal, Slide } from '@mui/material'
import { Link } from 'react-router-dom'
import profilePicture from '/assets/Icon/profile.svg'
import React, { useEffect, useState } from 'react'
import Dot from '/assets/Icon/Dot.svg'
import wallet from '/assets/Icon/WalletOutline.svg'
import trophy from '/assets/Icon/Trophy.svg'
import callout from '/assets/Icon/Callout.svg'
import useExchangeRate from 'hooks/useExchangeRate'
import useCollection from 'hooks/useCollection'
import MoonpayModal from 'components/Modal/MoonpayModal'
import CustomButton from 'components/Button/CustomButton'
import { clearNotifications, getNotifications } from 'utils/notifications'
import bellIcon from '/assets/Icon/Bell.svg'
import WalletCard from 'components/Card/WalletCard'
import { Add } from '@mui/icons-material'
import { lookupWallets } from 'services/api.service'
import Coach from 'components/DataDisplay/Coach'

const UserMenu = () => {
  const { logOut, user } = useAuth()
  const {
    flowUser,
    isConnected,
    flowWUSDCBalance,
    usingMagic,
    lastUsedWallet,
    checkAndUpdateUsersRegisteredWallets,
    userAuth
  } = useWallets()
  const {
    createCollection,
    collection,
    loading: collectionLoading
  } = useCollection(flowUser)
  const [userName, setUserName] = useState('')
  const [moonpayModal, showMoonpayModal] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(false)
  const [notification, setNotification] = useState('')

  const { rate } = useExchangeRate()

  const userBalanceAUD = React.useMemo(() => {
    const wusdcBalance = Number(flowWUSDCBalance)
    const balanceInAUD = wusdcBalance * rate
    return balanceInAUD.toFixed(2)
  }, [flowWUSDCBalance, rate])

  const fetchUserName = async () => {
    let userNameOrWalletAddress = flowUser?.addr ?? user?.preferred_username
    if (flowUser?.addr) {
      const _userName = await lookupWallets([flowUser?.addr])
      userNameOrWalletAddress =
        _userName[0]?.username ?? _userName[0]?.WalletAddress
    }
    setUserName(userNameOrWalletAddress)
  }

  useEffect(() => {
    fetchUserName()
  }, [user, flowUser?.addr, isConnected, usingMagic])

  const handleOpenUserMenu = () => {
    fetchUserName()
    checkAndUpdateUsersRegisteredWallets()
    setOpen(true)
  }

  async function createCollectionAsync() {
    try {
      await createCollection()
    } catch (e) {
      // add papertrail(e)
      console.error(e)
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    handleOpenUserMenu()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const signOut = () => {
    logOut()
    handleClose()
  }

  const closeMoonpayModal = () => {
    showMoonpayModal(false)
  }

  useEffect(() => {
    setNotification(getNotifications().newPacks ?? '')
  }, [])

  const dismissNotifications = () => {
    clearNotifications()
  }

  const trophyCabinetLinks = [
    {
      label: 'Trophy Cabinet',
      link: '/trophy-cabinet'
    },
    {
      label: 'Collectibles',
      link: '/trophy-cabinet?section=collectibles'
    },
    {
      label: 'Packs',
      link: '/trophy-cabinet?section=packs'
    },
    {
      label: 'Sets',
      link: '/trophy-cabinet?section=sets'
    },
    {
      label: 'Rewards',
      link: '/trophy-cabinet?section=rewards'
    },
    {
      label: 'Watchlist',
      link: '/trophy-cabinet?section=watchlist'
    },
    {
      label: 'Activity',
      link: '/trade-history'
    },
    {
      label: 'Settings',
      link: '/account'
    }
  ]

  return (
    <>
      <div className="flex justify-end h-full">
        {notification && (
          <img
            src={callout}
            alt=""
            className="block absolute top-[52px] right-[28px] z-[1] w-[160px] sm:!right-[52px] xsm:right-[36px] smd:hidden"
            onClick={() => dismissNotifications()}
          />
        )}
        <div className="flex relative items-center smd:hidden smd:h-[60px]">
          <Link to="/trophy-cabinet" onClick={() => dismissNotifications()}>
            <img
              src={trophy}
              alt=""
              className="mx-2 h-[27px] sm:mx-4 xsm:h-[31px] "
            />
            {notification && (
              <div
                className={`flex absolute top-[0] right-1 justify-center items-center w-[20px] h-[18px] bg-blue2 sm:right-3 ${
                  notification?.length > 9
                    ? 'rounded-[8px] px-[12px]'
                    : 'rounded-[10px]'
                }`}
              >
                <p className="text-xs font-bold tracking-normal text-white ">
                  {notification}
                </p>
              </div>
            )}
            {notification && (
              <div
                className={`absolute top-12 right-1/2 translate-x-[50%] flex`}
                onClick={() => dismissNotifications()}
              >
                <Coach>
                  <div className="pb-4 text-white font-bold text-[13px] tracking-wide leading-[18px]">
                    Your new item is now in your Trophy Cabinet
                  </div>
                  <div className="flex justify-end w-full">
                    <CustomButton
                      type="button"
                      model="secondary"
                      size="small"
                      variant="contained"
                      label="Take a look"
                    />
                  </div>
                </Coach>
              </div>
            )}
          </Link>
        </div>
        <Link className="pt-1 pl-1 pr-3" to="/trade-history">
          <img src={bellIcon} />
        </Link>
        <button
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          color="primary"
          className="flex justify-end items-center"
        >
          <img
            src={wallet}
            alt=""
            className="hidden mx-2 h-[27px] xsm:h-[31px] smd:block"
          />
          {!isConnected ? (
            <div className="flex items-start mr-4 ml-[-16px] h-[26px]">
              <img src={Dot} alt="Red dot" className="inline align-top" />
            </div>
          ) : (
            <div className="hidden mr-3 font-bold text-white sm:w-auto smd:block relative">
              <p className="align-bottom">A$ {userBalanceAUD}</p>
            </div>
          )}
          <img
            src={profilePicture}
            alt="Profile"
            className="h-2/3 xsm:h-[29px]"
          />
          {!collection && (
            <div className="flex items-start ml-[-7px] h-[26px]">
              <img src={Dot} alt="Red dot" className="inline align-top" />
            </div>
          )}
        </button>
      </div>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          overflow: 'visible',
          '& .MuiPaper-root': {
            backgroundColor: 'transparent',
            overflowY: 'auto',
            overflowX: 'visible',
            boxShadow: 'none'
          }
        }}
        className="modal-bg"
      >
        <div className="flex">
          <div
            onClick={() => setOpen(false)}
            className="flex justify-center items-center w-[30px] h-[30px] bg-white cursor-pointer"
          >
            <Add
              fontSize="large"
              style={{
                transform: `rotate(45deg)`,
                color: '#000000'
              }}
            />
          </div>
          <div className="min-w-[340px] max-w-[340px] min-h-screen relative overflow-visible p-6 text-center text-white bg-black1">
            <div className="flex flex-col justify-start items-start w-full">
              <p className="text-xl font-bold !leading-[18px] pb-1">
                {userName}
              </p>
              <Link
                onClick={() => setOpen(false)}
                to="/account?section=mint-profile"
                className="text-xs font-bold !leading-[18px] text-grey2"
              >
                Edit profile
              </Link>
            </div>
            <div className="flex flex-col gap-y-3 items-start py-6 w-full">
              {trophyCabinetLinks.map((link, index) => (
                <Link to={link.link} key={index}>
                  <p
                    onClick={() => setOpen(false)}
                    className={`text-grey2 hover:text-white text-lg !leading-5 font-bold transition-all`}
                  >
                    {link.label}
                  </p>
                </Link>
              ))}
            </div>
            <div className="flex flex-col gap-y-4 justify-center w-full">
              <div
                className="faq-line"
                style={{ margin: '0rem', width: '100%' }}
              ></div>
              <div className="flex justify-between items-center w-full">
                <p className="text-sm font-bold !leading-4">Connected Wallet</p>
                <Link
                  to="/account?section=manage-wallets"
                  onClick={() => setOpen(false)}
                >
                  <CustomButton
                    label="Manage"
                    model="secondary"
                    variant="contained"
                    className="!py-2 !pr-2 !pl-4"
                    hasEndIcon={true}
                  />
                </Link>
              </div>
              <div>
                <div className="flex flex-col gap-y-3 w-full">
                  {isConnected && !collection && !collectionLoading && (
                    <div
                      className="w-full cursor-pointer"
                      onClick={() => createCollectionAsync()}
                    >
                      <p className="text-lg font-bold !leading-5 text-left">
                        Activate Trophy Cabinet{' '}
                        {/* change wording to allow for wrapped USDC */}
                        <img
                          src={Dot}
                          alt="Red dot"
                          className="inline align-top"
                        />
                      </p>
                    </div>
                  )}
                  <WalletCard
                    walletName={lastUsedWallet === 'magic' ? 'Magic' : 'Blocto'}
                  />
                </div>
              </div>
              <div
                className="faq-line"
                style={{ margin: '0rem', width: '100%' }}
              ></div>
              <div className="flex flex-col gap-y-4 items-start w-full text-left">
                <a
                  href="https://aflmint.zendesk.com/hc/en-gb"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="font-inter text-sm font-bold tracking-wide text-grey2 hover:text-white transition-all">
                    Help Centre
                  </p>
                </a>
                <div onClick={signOut} className="cursor-pointer">
                  <p className="font-inter text-sm font-bold tracking-wide text-grey2 hover:text-white transition-all">
                    Log Out
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <MoonpayModal
        open={moonpayModal}
        onClose={closeMoonpayModal}
        mode="funds"
      />
    </>
  )
}

export default UserMenu
