import React, { useState } from 'react'
import { Box, Snackbar } from '@mui/material'
import Alert, { AlertColor } from '@mui/material/Alert'
import CheckIcon from '@mui/icons-material/Check'
import {
  SnackbarContext,
  SnackbarContextType,
  SnackbarState
} from './SnackbarContext'

type SnackbarProviderProps = {
  children: React.ReactNode
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({
  children
}) => {
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    isOpen: false,
    message: '',
    dismissDuration: 2000,
    severity: 'success'
  })

  const openSnackbar = (
    message: string,
    dismissDuration = 2000,
    severity: AlertColor = 'success'
  ) => {
    setSnackbar({
      isOpen: true,
      message,
      dismissDuration,
      severity
    })
  }

  const closeSnackbar = () => {
    setSnackbar({
      isOpen: false,
      message: '',
      dismissDuration: 2000,
      severity: 'success'
    })
  }

  const contextValue: SnackbarContextType = {
    snackbar,
    openSnackbar,
    closeSnackbar
  }

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbar.isOpen}
        autoHideDuration={
          snackbar.dismissDuration !== 0 ? snackbar.dismissDuration : null
        }
        onClose={closeSnackbar}
      >
        <Box sx={{ width: '100%' }}>
          <Alert
            onClose={closeSnackbar}
            sx={{ width: '100%' }}
            icon={<CheckIcon fontSize="inherit" />}
            variant="filled"
            severity={snackbar.severity}
            className={
              snackbar.severity === 'success'
                ? `!bg-[#1976d2]`
                : snackbar.severity === 'error'
                ? `!bg-[#d32f2f]`
                : snackbar.severity === 'warning'
                ? `!bg-[#ffa000]`
                : snackbar.severity === 'info'
                ? `!bg-[#2196f3]`
                : `!bg-[#1976d2]`
            }
          >
            <b>{snackbar.message}</b>
          </Alert>
        </Box>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}
