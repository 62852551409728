import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  ReactNode
} from 'react'
import { decode, getTransactionStatus, tx, send } from '@onflow/fcl'
import Spinner from 'components/Transactions/Spinner'
import TransactionDetails from 'components/Transactions/TransactionDetails'

interface Transaction {
  id: string
  message?: string
  status: number
}

interface State {
  runningTxs: boolean
  transactions: Transaction[]
  addTx: (txId: string, message?: string) => void
}

interface TransactionsContextProps {
  children: ReactNode
}

const transactionsContextDefaultValues: State = {
  runningTxs: false,
  transactions: [],
  addTx: () => null
}

const TransactionsContext = createContext<State>(
  transactionsContextDefaultValues
)

export default function TransactionsProvider({
  children
}: TransactionsContextProps) {
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getLocalTxs()
  }, [])

  useEffect(() => {
    // Subscribe to transaction status updates for each transaction
    transactions.forEach((transaction) => {
      const subscription = tx(transaction.id).subscribe((s: any) =>
        updateTxStatus(s?.status, transaction?.id)
      )

      // Unsubscribe from the previous subscription
      return () => {
        subscription.unsubscribe()
      }
    })
  }, [])

  const renderTxs = useCallback(() => {
    return (
      <div className="fixed top-20 right-4 z-[9999999]">
        {transactions.map((tx: Transaction) => (
          <TransactionDetails
            id={tx?.id}
            key={tx?.id}
            message={tx?.message}
            status={tx?.status}
          />
        ))}
      </div>
    )
  }, [transactions])

  const getLocalTxs = async () => {
    const txString = JSON.parse(window.localStorage.getItem('txs') || '[]')

    if (!txString || txString.length === 0) {
      setLoading(false)
      return
    }

    const localTxs = txString
    const runningTxs = []

    for (let i = 0; i < localTxs.length; i++) {
      const ltx = localTxs[i]
      const t = await getTxStatus(ltx?.id)
      if (t?.status === 4 || t?.status === -1) {
        continue
      } else {
        tx(ltx?.id).subscribe((s: any) => updateTxStatus(s?.status, ltx?.id))
        runningTxs.push(ltx)
      }
    }

    window.localStorage.setItem('txs', JSON.stringify(runningTxs))
    setTransactions(
      runningTxs.map((t) => ({
        id: t?.id,
        message: t?.message,
        status: t?.status
      }))
    )

    setLoading(false)
  }

  const getTxStatus = async (id: string) => {
    const status = await send([getTransactionStatus(id)]).then(decode)
    return status
  }

  const addTx = (txID: string, message?: string) => {
    const transaction: Transaction = { id: txID, message, status: -1 }
    setTransactions((prev) => [...prev, transaction])
    tx(txID).subscribe((s: any) => updateTxStatus(s?.status, transaction?.id))
    window.localStorage.setItem(
      'txs',
      JSON.stringify([...transactions, transaction])
    )
  }

  const updateTxStatus = (status: number, txID: string) => {
    if (status === 4) {
      removeTx(txID)
      return
    }
    setTransactions((prev) =>
      prev.map((tx: Transaction) => (tx.id === txID ? { ...tx, status } : tx))
    )
  }

  const removeTx = (txID: string) => {
    setTransactions((prev) => prev.filter((tx: Transaction) => tx.id !== txID))
  }

  if (loading) return <Spinner />
  return (
    <TransactionsContext.Provider
      value={{
        runningTxs: transactions.length !== 0,
        transactions,
        addTx
      }}
    >
      {renderTxs()}
      {children}
    </TransactionsContext.Provider>
  )
}

const useTransactions = () => {
  const context = useContext(TransactionsContext)
  if (context === undefined) {
    throw new Error('useTransactions must be used within a TransactionsContext')
  }
  return context
}

export { TransactionsProvider, useTransactions, TransactionsContext }
