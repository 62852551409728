import React, { Dispatch, SetStateAction } from 'react'

import { Modal } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Spinner from 'components/Transactions/Spinner'

export interface SpinnerModalProps {
  spinnerModalOpen: boolean
  setSpinnerModalOpen: Dispatch<SetStateAction<boolean>>
}

const SpinnerModal = ({
  spinnerModalOpen,
  setSpinnerModalOpen
}: SpinnerModalProps) => {
  return (
    <Modal
      open={spinnerModalOpen}
      onClose={setSpinnerModalOpen}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      className="overflow-auto modal-bg"
    >
      <div className="flex absolute top-1/2 left-1/2 flex-col justify-center items-center px-4 w-full h-full translate-x-[-50%] translate-y-[-50%]">
        <div className="flex flex-col gap-6 items-center">
          <Spinner isLarge={true} />
          <p className="text-2xl text-white">Connecting</p>
        </div>
      </div>
    </Modal>
  )
}

export default SpinnerModal
