import Button, { ButtonProps } from '@mui/material/Button'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import ChevronBtn from './Chevron'
import ExternalLink from './ExternalLinkIcon'

const theme = createTheme()
const useButtonStyles = {
  primary: {
    backgroundColor: '#096CE0',
    borderRadius: '3px',
    color: '#ffffff',
    '&.MuiButton-root:hover': {
      backgroundColor: '#2684F2'
    },
    '&.MuiButton-root:active': {
      background: '#096CE0'
    }
  },
  secondary: {
    backgroundColor: '#1A1A1A',
    borderRadius: '3px',
    color: '#ffffff',
    fontFamily: 'Inter',
    fontSize: '15px',
    textTransform: 'none',
    lineHeight: '16px',
    fontWeight: 'bold',
    padding: '12px 24px',
    '&.MuiButton-root:hover': {
      backgroundColor: '#2F2F2F'
    }
  },
  disabled: {
    backgroundImage: 'linear-gradient(85deg, #ffffff 100%, #d3d3d3 100%)',
    borderColor: '#d3d3d3',
    borderRadius: '3px',
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: '15px',
    textTransform: 'none',
    lineHeight: '16px',
    fontWeight: '800',
    padding: '12px 24px',
    '&.MuiButton-root:hover': {
      boxShadow: '0px 3px 10px rgba(95, 118, 247, 0.4)'
    }
  },
  'disabled-black': {
    backgroundColor: '#1A1A1A',
    borderRadius: '3px',
    color: '#A6A6A6',
    fontFamily: 'Inter',
    fontSize: '15px',
    textTransform: 'none',
    lineHeight: '16px',
    fontWeight: 'bold',
    padding: '12px 24px',
    '&.MuiButton-root:hover': {
      backgroundColor: '#1d1d1d'
    }
  },
  text: {
    color: '#096CE0',
    fontFamily: 'PODIUMSharp-49',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.1em',
    '&.MuiButton-root:hover': {}
  },
  small: {
    padding: '7px'
  },
  medium: {
    padding: '7px'
  },
  large: {
    padding: '10px'
  }
}

const useButtonSizeStyles = {
  small: {
    minWidth: '0px',
    fontFamily: 'Inter',
    fontSize: '12px',
    textTransform: 'none',
    lineHeight: '18px',
    fontWeight: 'bold',
    padding: '6px 12px'
  },
  medium: {
    minWidth: '0px',
    fontFamily: 'Inter',
    fontSize: '15px',
    textTransform: 'none',
    lineHeight: '16px',
    fontWeight: 'bold',
    padding: '12px 24px'
  }
}

export type CustomButtonProps = ButtonProps<
  'button',
  {
    model: 'primary' | 'secondary' | 'text' | 'disabled' | 'disabled-black'
    size?: 'small' | 'medium'
    label?: string | React.ReactElement
    className?: string
    fontColor?: string
    hasEndIcon?: boolean
    isExternalLink?: boolean
  }
>

const CustomButton = ({
  model,
  size = 'medium',
  label,
  className,
  onClick,
  hasEndIcon,
  isExternalLink,
  ...rest
}: CustomButtonProps) => {
  return (
    <ThemeProvider theme={theme}>
      <Button
        className={`${className}`}
        sx={{
          ...useButtonStyles[model],
          ...useButtonSizeStyles[size]
        }}
        onClick={onClick}
        {...rest}
        endIcon={
          hasEndIcon && isExternalLink ? (
            <ExternalLink fill={'white'} />
          ) : hasEndIcon ? (
            <ChevronBtn sx={{ padding: '0px 0px 3px', marginLeft: '-8px' }} />
          ) : null
        }
      >
        {label}
      </Button>
    </ThemeProvider>
  )
}

export default CustomButton
