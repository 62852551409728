import React from 'react'
import arrow from '/assets/Icon/Arrow_Down.svg'

const BackToTop = () => {
  const backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const [scrollDistance, setScrollDistance] = React.useState(false)

  React.useMemo(() => {
    const viewScrollToTop = () => {
      if (window.scrollY > 900) {
        setScrollDistance(true)
      } else {
        setScrollDistance(false)
      }
    }
    window.addEventListener('scroll', viewScrollToTop)
    return () => {
      window.removeEventListener('scroll', viewScrollToTop)
    }
  }, [])

  if (!scrollDistance) return null
  return (
    <div className="flex fixed right-0 bottom-0 z-[10000] justify-center items-center py-6 px-4">
      <button
        className="p-2 bg-white rounded-[50%]"
        onClick={() => backToTop()}
      >
        <img
          src={arrow}
          alt=""
          className="inline pt-[2px] w-6 h-6 rotate-180"
        />
      </button>
    </div>
  )
}

export default BackToTop
