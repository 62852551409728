import { ReactNode, createContext, useContext } from 'react'
import useCurrentUser from '../hooks/useCurrentUser'
import { ExtendedUserClaims } from '../types'

interface State {
  signedUser: boolean
  user: ExtendedUserClaims | null
  loggedIn: boolean
  logIn?: any
  signUp?: any
  logOut?: any
  isDevLoggedIn?: boolean
  setIsDevLoggedIn?: any
  userNeedsToActivateAccount?: boolean
}

interface AuthContextProps {
  children: ReactNode
}

const gameContextDefaultValues: State = {
  signedUser: false,
  user: null,
  loggedIn: false,
  logIn: null,
  signUp: null,
  logOut: null,
  isDevLoggedIn: false,
  setIsDevLoggedIn: null,
  userNeedsToActivateAccount: false
}

const AuthContext = createContext<State>(gameContextDefaultValues)

export default function AuthProvider({ children }: AuthContextProps) {
  const {
    signedUser,
    user,
    isLogged,
    tools,
    setIsDevLoggedIn,
    isDevLoggedIn,
    userNeedsToActivateAccount
  } = useCurrentUser()

  return (
    <AuthContext.Provider
      value={{
        signedUser,
        user,
        loggedIn: !!isLogged,
        logIn: tools.logIn,
        signUp: tools.signUp,
        logOut: tools.logOut,
        isDevLoggedIn,
        setIsDevLoggedIn,
        userNeedsToActivateAccount
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth, AuthContext }
