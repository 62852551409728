import React from 'react'
import select from '/assets/Icon/Select_On.svg'

export interface BenefitsBlockProps {
  content: string
}

const BenefitsBlock = ({ content }: BenefitsBlockProps) => {
  return (
    <div className="flex justify-start items-center w-full">
      <img src={select} alt="" className="mr-3 w-[18px]" />
      <p className="text-grey3">{content}</p>
    </div>
  )
}

export default BenefitsBlock
