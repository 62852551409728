import { Grid } from '@mui/material'
import ChevronBtn from 'components/Button/Chevron'
import { Toggle2 } from 'components/StateMod/Toggle'
import { LayoutGroup } from 'framer-motion'
import React from 'react'
import ZendeskSection from './ZendeskSection'
import { faqs } from 'hooks/useFaqs'
import { Link } from 'react-router-dom'

export interface Props {
  header: string
  faqName: string
  marginTop?: string
  id?: string
  paddingX?: string
  hasExternalContact?: boolean
  className?: string
}

const FaqSection = ({
  id,
  header,
  faqName,
  marginTop = 'mt-14',
  paddingX = 'px-6',
  hasExternalContact = true,
  className = ''
}: Props) => {
  const currentFaq = faqs[faqName]

  return (
    <div
      id={id}
      className={`flex flex-col justify-center items-center pt-10 w-full lg:${marginTop} ${paddingX} ${
        !hasExternalContact && 'pb-10'
      } ${className}`}
    >
      <div className="w-full max-w-[900px]">
        <p className="font-podium49 text-2xl tracking-wider leading-6 uppercase">
          {header}
        </p>
        <LayoutGroup>
          <Grid className={`w-full`}>
            <div
              className="faq-line"
              style={{ margin: '2rem 0rem', width: '100%' }}
            ></div>
            {currentFaq?.map((faq, index) => (
              <Toggle2 title={faq.question} width="100%" key={index}>
                <div className="!py-2 text-grey3 answer">
                  <p className={faq.ctaLink && 'pb-4'}>{faq.answer}</p>
                  {faq.ctaLink && faq.ctaText && (
                    <Link to={faq.ctaLink} className="pr-1 ml-auto lg:pr-6">
                      <button className="font-bold text-blue3 sm:text-lg ">
                        {faq.ctaText}{' '}
                        <ChevronBtn sx={{ margin: '0 0 8px 0px' }} />
                      </button>
                    </Link>
                  )}
                </div>
              </Toggle2>
            ))}
            {hasExternalContact && (
              <Grid className="mt-8 mb-20 text-left">
                <a
                  href="https://aflmint.zendesk.com/hc/en-gb"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="text-lg font-bold tracking-wide text-blue3">
                    See all FAQs{' '}
                    <ChevronBtn
                      sx={{ color: 'blue3', margin: '0 0 5px 2px' }}
                    />
                  </button>
                </a>
              </Grid>
            )}
          </Grid>
        </LayoutGroup>
        {hasExternalContact && <ZendeskSection />}
      </div>
    </div>
  )
}

export default FaqSection
