const NOTIFICATIONS_LOCALSTORAGE_KEY = `notifications`

interface Notifications {
  newPacks: string
}

export const getNotifications = () => {
  const str = window.localStorage.getItem(NOTIFICATIONS_LOCALSTORAGE_KEY) || ''
  try {
    const data = JSON.parse(str) as Notifications
    return data
  } catch {
    return { newPacks: '' } as Notifications
  }
}

export const clearNotifications = () =>
  window.localStorage.setItem(NOTIFICATIONS_LOCALSTORAGE_KEY, '')

export const newPackNotification = () => {
  const newPacks = getNotifications().newPacks
  if (parseInt(newPacks)) {
    window.localStorage.setItem(
      NOTIFICATIONS_LOCALSTORAGE_KEY,
      JSON.stringify({
        newPacks: (parseInt(newPacks) + 1).toString()
      })
    )
  } else {
    window.localStorage.setItem(
      NOTIFICATIONS_LOCALSTORAGE_KEY,
      JSON.stringify({
        newPacks: '1'
      })
    )
  }
}
