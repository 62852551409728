import { useEffect, useState, useRef } from 'react'
import { getExchangeRate } from 'services/api.service'

export default function useExchangeRate() {
  const [rate, setRate] = useState<number>(1.432665)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const cachedRate = localStorage.getItem('exchangeRate')
        const cachedTimestamp = localStorage.getItem('exchangeRateTimestamp')

        const now = Date.now()
        const oneMinute = 60 * 1000

        if (
          cachedRate &&
          cachedTimestamp &&
          now - parseInt(cachedTimestamp) < oneMinute
        ) {
          setRate(Number(cachedRate))
          return // Skip fetching the exchange rate if the cached timestamp is less than 1 minute old
        }

        const audRate = await getExchangeRate()
        if (audRate?.rate?.rate) {
          const newRate = Number(audRate.rate.rate)
          setRate(newRate)
          localStorage.setItem('exchangeRate', newRate.toString())
          localStorage.setItem('exchangeRateTimestamp', now.toString())
        }
      } catch (e) {
        console.error(e)
        // add papertrail(e)
      }
    }

    fetchExchangeRate()

    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    intervalRef.current = setInterval(fetchExchangeRate, 60000) // Fetch every 1 minute

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  const formatNumber = (num: number): string => {
    return num.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }

  const formatPrice = (input: string | number): string => {
    const price =
      typeof input === 'string' ? Number(input.replaceAll(',', '')) : input
    return formatNumber(price)
  }

  const formatAUDPrice = (input: string | number): string => {
    const price =
      typeof input === 'string' ? Number(input.replaceAll(',', '')) : input
    const AUDPrice = Number((price * rate).toFixed(2))
    return formatNumber(AUDPrice)
  }

  const formatUSDPrice = (input: string | number) => {
    const price =
      typeof input === 'string' ? Number(input.replaceAll(',', '')) : input
    const USDPrice = Number((price / rate).toFixed(2))
    return formatNumber(USDPrice)
  }

  const USDPrice = (price: string) => {
    return Number((Number(price) || 0) / rate).toFixed(2)
  }

  const AUDtoUSD = (input: string | number) => {
    const price =
      typeof input === 'string' ? Number(input.replaceAll(',', '')) : input
    return parseFloat(formatUSDPrice(price).replaceAll(',', ''))
  }

  return {
    rate,
    formatPrice,
    formatAUDPrice,
    formatUSDPrice,
    USDPrice,
    AUDtoUSD
  }
}
