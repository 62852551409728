import ChevronBtn from 'components/Button/Chevron'
export interface Faq {
  question: string
  answer: string | JSX.Element
  ctaLink?: string
  ctaText?: string
}

export interface FaqGroup {
  [key: string]: Faq[]
}

export const faqs: FaqGroup = {
  buyingPage: [
    {
      question: 'Ways to purchase a collectible',
      answer:
        'During this first Beta release of the Marketplace you can only purchase a collectible using funds in your wallet. You can top up your wallet using a credit or debit card from the account menu. Purchasing directly with a credit or debit card will be added in a future release.'
    },
    {
      question: 'Can I cancel or return a purchase?',
      answer:
        'No, once you’ve approved the transaction in the Blocto popup that means you’ve confirmed the purchase and the collectible will now belong to you. A collectible can’t be returned or refunded.'
    },
    {
      question: 'Choosing a serial number',
      answer:
        'Some collectors like to collect certain types of serial numbers such as a serial that matches the players guernsey number or a low serial number. You may see these types of serial number listed for a higher price than others.'
    },
    {
      question: 'Purchase troubleshooting',
      answer: (
        <div>
          <p>
            If you experience any issues with purchasing a collectible send us a
            message and we’ll investigate.
          </p>
          <a
            href="https://support.aflmint.com.au/hc/en-gb/requests/new "
            target="_blank"
            rel="noreferrer"
          >
            <button className="font-bold tracking-wide text-blue3">
              Send us a message{' '}
              <ChevronBtn sx={{ color: 'blue3', margin: '0 0 5px 2px' }} />
            </button>
          </a>
        </div>
      )
    }
  ],
  sellingPage: [
    {
      question: 'How do I determine the price to list my collectible at?',
      answer:
        'The price for your Collectible will depend on several factors, including its rarity, uniqueness, and demand in the market. Some things that collectors may look for on AFL Mint are a serial number that matches the players guernsey, low serial numbers, highest serial number available. If there is a challenge with that moment this can also increase demand for that moment while the challenge is happening. View the pricing guide above to see the price other collectors have listed this item at.'
    },

    {
      question:
        "Can I adjust the price of my Collectible after it's listed for sale?",
      answer:
        "No, this feature is not available at this time. If you notice that your Collectible isn't receiving any offers, you can cancel your listing and relist with a different price to make it more appealing to potential buyers. There is no charge for cancelling and relisting a collectible."
    },
    {
      question: 'Does it cost anything to list on the Marketplace?',
      answer: `No, it’s free to list on the AFL Mint Marketplace. If your collectible sells,
      ${parseFloat(import.meta.env.VITE_MARKETPLACE_FEE) * 100 ?? 8}%
      of the listing price is deducted in royalties at the time of sale. If your collectible does not sell you will not be charged any fees.`
    },
    {
      question: 'Can I set a time limit or auction for my listing?',
      answer:
        'No, this feature is not available at this time but may become available in the future.'
    },
    {
      question: 'Can I list as a private sale?',
      answer:
        'No, this feature is not available at this time but may become available in the future.'
    },
    {
      question: 'Can I trade instead of listing for sale?',
      answer:
        'No, this feature is not available at this time but may become available in the future.'
    }
  ],
  packsPage: [
    {
      question: 'What is a pack?',
      answer:
        'Packs are a group of NFTs bundled for sale. Packs will vary in size based on the release. You will be able to see exactly how many NFTs are in each pack that you choose prior to purchasing.'
    },
    {
      question: 'What is a serial number?',
      answer:
        'A serial number identifies exactly which unique Moment NFT, out of the total number available, you specifically own.'
    },
    {
      question: 'What is a nft drop?',
      answer: `A NFT drop is the release of a non-fungible token project.
        A drop refers to the exact date, time, and generally the
        minting price of the NFT. Many NFT drops have purchase
        limits that apply to the number of NFTs you are able to
        mint in one transaction.`
    },
    {
      question: 'When do new drops happen?',
      answer: `Pack drop times will vary, so sign up to get notified of
        upcoming pack drops. You will need to sign up for an AFL
        Mint account to get notified of release dates, roadmap
        features, unlocked utilities and future drops.`
    },
    {
      question: 'How do I learn about upcoming drops?',
      answer: `Sign up for an AFL Mint account to receive emails about
            our latest pack drops or follow us on social media or
            Discord.`
    },
    {
      question: `What if I don't get a pack in a drop?`,
      answer: `We will release pack drops throughout the season. You can
        also buy a starter pack or individual NFTs when the
        marketplace is launched.`
    }
  ],
  genesisPage: [
    {
      question: 'What is the process of getting a Genesis ball?',
      answer: `Genesis balls are allocated at random to AFL Mint users
        post purchase of a pack via an airdrop. The number of
        balls available per drop will be capped, and disclosed at
        each drop. Any promotional prizes, gifts or activities
        will be subject to the AFL Mint Terms and Conditions. AFL
        Mint and Animoca Brands have reserved a number of balls
        for promotional and marketing purposes. These are included
        in the total number.`
    },
    {
      question: 'How are the Genesis balls distributed?',
      answer: `To ensure fair allocation of Genesis balls, we use a
        randomiser script to randomly assign Genesis balls to
        packs during the minting process. The computations will
        take the total number of balls and randomly assign a
        secondary number to the original number. Each Genesis
        ball’s secondary number will be its minting allocated
        number in the queue. Once the Genesis balls have been put
        into market, there will be no more.`
    },
    {
      question: 'How do I reveal my Genesis ball?',
      answer: (
        <div>
          <ul className="pl-5 list-decimal">
            <li>
              Click on a placeholder ball in the Rewards tab of your Trophy
              Cabinet to open the details view
            </li>
            <li>Click the Exchange button </li>
            <li>See the instructions page and click the Reveal button </li>
            <li>Sign the Blocto transaction </li>
            <li>Start the reveal to see your unique Genesis ball! </li>
          </ul>
          <p className="pt-3">
            Each stage of the process will guide you along the way. Have fun!
          </p>
        </div>
      )
    },
    {
      question: 'How is my Genesis ball stored?',
      answer: (
        <div>
          <p>
            Genesis Ball media files will be stored on AWS servers and linked to
            the Smart Contract through an Amazon S3 bucket unique ID. The smart
            contracts have been audited by neutral third party Halborn Inc. and
            passed inspection. These contracts are available to view on{' '}
            <a
              href="https://flowscan.io/"
              target="_blank"
              rel="noreferrer"
              className="underline hover:no-underline"
            >
              Flowscan
            </a>
            .
          </p>
        </div>
      )
    }
  ],
  ballExchangePage: [
    {
      question: 'Can I choose the design of my Genesis ball?',
      answer:
        'No, the design of your Genesis ball will be assigned randomly from a pre-set selection of designs. Once the Marketplace becomes available, you will have the opportunity to purchase Genesis balls from other collectors or trade yours with another collector.'
    },
    {
      question: 'Will my serial number be the same?',
      answer:
        'Yes, your serial number will be the same as your placeholder ball.'
    },
    {
      question: 'Can I keep my placeholder ball as well?',
      answer: ` No, once you burn your placeholder ball, it becomes
      permanently unusable and you cannot keep it. The burn
      process is a one-way exchange, where you give up your
      placeholder ball to receive a new digital collectible, your
      unique Genesis ball.`
    },
    {
      question: 'Can I sell my placeholder ball on the Marketplace?',
      answer: `Yes, you can leave a placeholder ball unrevealed and sell it on the Marketplace for the new owner to reveal.`
    },
    {
      question: 'What does ‘burning’ a collectible mean?',
      answer: `Burning a digital collectible means destroying it, or making it unusable. This process is often used to exchange one digital collectible for another, or to get rid of an unwanted digital collectible. When you burn a digital collectible, you permanently give it up in exchange for a new one. It's important to know that once a digital collectible is burned, it cannot be recovered.`
    }
  ],
  momentBurnPage: [
    {
      question: 'Which moments are available for upgrading?',
      answer:
        'Ripper Skipper moments from the 2024 drop are the only moments available for upgrading.'
    },
    {
      question: 'How do I upgrade my moment?',
      answer: (
        <div>
          <p className="pb-2">
            To upgrade your moment, there are two steps that needs to be
            followed. Firstly, you will need to select which serial numbers from
            the moment that you own, you would want to upgrade. You can select
            multiple serial numbers to upgrade at once.
          </p>
          <p className="pb-2">
            Secondly, you will need to select the required number of moments to
            be burned to upgrade the selected serial numbers. The required
            number of moments to be burned is based on the tier of the moment
            that you are upgrading.
          </p>
          <p>
            After you have selected the required amount of moments, click on the
            "Exchange your moments now" button to initiate the exchange.
          </p>
        </div>
      )
    },
    {
      question: 'Which moments can be selected to burn?',
      answer:
        'You can only select moments that are from the previous two Ripper Skipper drops to burn in order to upgrade the new moment. The moment must also NOT be listed on the Marketplace.'
    },
    {
      question: 'Does my serial number change after upgrading?',
      answer:
        'No, your serial number/s will remain the same and transferred and minted to the new upgraded moment/s.'
    },
    {
      question: 'Can I upgrade multiple moments at once?',
      answer:
        'No, you can only select multiple serial numbers from the SAME moment to upgrade at once. The required number of moments to be burned is based on the tier of the moment that you are upgrading.'
    }
  ],
  tokenBurnPage: [
    {
      question: 'Which Rookie Tokens are available for exchanging?',
      answer:
        'Rookies who played during the 2024 season will have a moment to exchange for. This equates to 14 Rookies. The remaining 6 will be available for upgrading next year should they play a game.'
    },
    {
      question: 'How do I exchange my tokens?',
      answer: (
        <div>
          <p className="pb-2">
            To exchange your tokens, you need to head to your trophy cabinet and
            select the Rookie Token you want to exchange. From there, press the
            ‘Exchange ‘Players Name’ Tokens’ Button. From here, satisfy the
            criteria for the total amount of Rookie Tokens you need to exchange.
            You can exchange multiple tokens at once resulting in multiple
            moments.
          </p>
          <p className="pb-2">
            The amount of moments you are allocated depends on how many Rookie
            moments you decide to exchange for. The higher the moment tier, the
            more tokens you will need to exchange.
          </p>
          <p className="pb-2">
            Serial numbers of tokens have no bearing on the outcome of the
            moment. Exchanging a rare serial does not guarantee a rare moment
            serial in return.
          </p>
          <p className="pb-2">
            After you have selected the amount of tokens, click on the "Exchange
            your tokens now" button to initiate the exchange and allocation
            process.
          </p>
          <p>
            You will receive your moments after the conclusion of the redemption
            window.
          </p>
        </div>
      )
    },
    {
      question: 'Will I receive my moments in my wallet after the exchange?',
      answer:
        'No. There will be a window in which users can exchange their tokens. After the window has closed, the moments will be minted and randomly allocated to the users wallets. This gives all participants the opportunity to receive a rare serial number.'
    },
    {
      question: 'Why is there an exchange window?',
      answer:
        'In order to determine the maximum number of moments to be minted, we need to have an exchange window that can be closed off.'
    },
    {
      question:
        'Can I exchange different player Rookie Tokens in one transaction?',
      answer:
        'No, you can only select multiples of the SAME token to exchange at once. This helps with the calculation of the allocation that you will receive.'
    }
  ],
  momentExchangePage: [
    {
      question: 'Why were moments minted twice?',
      answer:
        'We encountered some duplicates when a line between our payment provider and our events indexing platform was broken. This lead to two people having a chance to buy the same pack at the same time. We were not aware of this until recently and have placed counter measures to prevent this in future.'
    },
    {
      question: 'Can I trade for the same moment?',
      answer:
        'The vast majority of exchanges have received a similar moment with a lower serial number. However, in the rare instances where a lower serial number for the same moment was not available, we opted to exchange your moment with another moment from the same tier. This replacement moment could be a common, deluxe, ovation, or guernsey serial from the same collection.'
    },
    {
      question: 'Can I keep my duplicate moments instead of trading?',
      answer: `You are free to hold your duplicate moments if you wish to do so. You will not be able to sell them or use in any rewards or challenges but you can keep them in your Trophy Cabinet if you don’t want to exchange them.`
    },
    {
      question: 'Why can’t I list a duplicate moment on the marketplace?',
      answer: `The duplicate moment was an error caused by system error, we can only list one of the moments with that serial on the marketplace. We can not allow more than 1 to be listed, we have chosen the first timestamped moment to have that serial number as the primary moment serial. All others are duplicates and can not be sold, they must be exchanged.`
    },
    {
      question: 'How do you calculate a fair trade?',
      answer: (
        <div>
          <p className="pb-3">
            The first version to be minted is considered the primary version and
            can be sold on the Marketplace. We have used the mint timestamp to
            determine this. Any other versions minted after the first one are
            considered duplicates.
          </p>
          <p>
            We’ll exchange duplicates for more than the value of the duplicate.
            Value is determined by the rarity tier and then with single digit or
            guernsey serials being more valuable, two digits valuable and triple
            digits less valuable.
          </p>
        </div>
      )
    },
    {
      question: 'Will duplicates occur again?',
      answer: (
        <div>
          <p className="pb-3">
            We use mint on demand for our minting mechanics. This is where we
            mint the packs and pick the serials as the transaction is
            successful. We are minting on demand.
          </p>
          <p>
            We have since placed counter measures to mitigate but not entirely
            prevent the duplicates from occurring in the future.
          </p>
        </div>
      )
    },
    {
      question: 'How do I know that my moment is a duplicate?',
      answer: (
        <div>
          <p className="pb-3">
            The AFL Mint team is scanning for duplicates on a weekly basis. On
            average the team is picking up about 1-2 single moments per week.
            This includes unopened packs.
          </p>
          <p>
            That duplicate moment is immediately identified and has a duplicate
            icon placed on the moment so the user knows it cannot be traded on
            the Marketplace.
          </p>
        </div>
      )
    }
  ],
  magicWallet: [
    {
      question: 'Why should I use Magic Wallet?',
      answer: (
        <div>
          <p className="pb-3">
            Magic wallet allows for an even easier sign-in experience when you
            are new to AFL Mint or a seasoned veteran. AFL Mint has crafted a
            unique sign in experience, in partnership with Magic. Making your
            digital wallet setup and sign-in more automated for you so you enjoy
            less clicks. Magic also comes with a FREE Team Collectible, just for
            creating an account with AFL Mint, it doesn’t get any better then
            that!
          </p>
          <p className="italic">
            *The wallet ID that you are allocated at this time will only be
            associated to AFL Mint. If you use Magic Wallet on any external
            platform you may not have the same wallet ID. You can also transfer
            your Blocto wallet AFL Mint digital collectibles to your Magic
            wallet in bulk and at your own leisure.
          </p>
        </div>
      )
    },
    {
      question: 'How can I add funds to Magic Wallet?',
      answer: (
        <div>
          <p className="pb-3">
            To add funds to your Magic wallet you can navigate to your account
            in the top right menu section and select manage wallets. Turn on the
            Magic wallet toggle if it's not already turned on. From there you
            should see a button for adding funds. Just follow the prompts to add
            your choice of funds to your wallet. Remember we use the USDC (FT)
            token to purchase AFL Mint.
          </p>
          <p className="pb-3">
            Funds can be added with a credit card and Google Pay for adding
            funds to your account. If you want to buy collectibles directly you
            will be able to use Apple Pay (coming soon). You can also transfer
            tokens to your newly created Magic wallet. Just make sure you are
            transferring from Flowchain USDC.
          </p>
          <p className="italic">
            *Depending on your financial institution, there may be additional
            rules and regulations with token and digital collectible purchases.
            Please contact your bank for additional information.
          </p>
        </div>
      )
    },
    {
      question: 'Is there a Magic Wallet app?',
      answer: (
        <div>
          <p>
            At the moment there is no Magic Wallet mobile app. You can log in
            through any browser when connecting to the AFL Mint website. Magic
            is plugged into AFL Mint and can be viewed in your profile, by
            simply clicking on your profile icon. There is simply no need for an
            app, saving you the time fussing around apps and wallets, how good
            is that!
          </p>
        </div>
      )
    },
    {
      question: 'Where can I learn more about Magic Wallet?',
      answer: (
        <div>
          <p>
            You can learn about the Magic wallet by visiting{' '}
            <a
              href="https://www.magic.link"
              target="_blank"
              rel="noreferrer"
              className="underline hover:no-underline"
            >
              https://www.magic.link
            </a>
            .
          </p>
        </div>
      )
    }
  ],
  bloctoWallet: [
    {
      question: 'Why should I use Blocto Wallet?',
      answer: (
        <div>
          <p className="pb-3">
            Blocto wallet allows for a seamless AFL Mint experience. Unlike
            other wallets, Blocto uses a points system to cover transaction
            fees. Making minting, transfers and swaps simple and free. With an
            in app browser, you can access and connect to purchase AFL Mint
            digital collectibles. Blocto offers choice in your minting
            experience, allowing secure token transfer/swaps, and much more
            features.
          </p>
          <p className="italic">
            *Once you have used up all your Blocto points for transactions,
            transaction fees may apply and are subject to Blocto wallets terms
            and conditions.
          </p>
        </div>
      )
    },
    {
      question: 'How can I add funds to Blocto Wallet?',
      answer: (
        <div>
          <p className="pb-3">
            To add funds to your Blocto wallet you can navigate to your account
            in the top right menu section and select manage wallets. Turn on the
            Blocto wallet toggle, if it's not already turned on. From there you
            should see a button for adding funds. Just follow the prompts to add
            your choice of funds to your wallet. Remember we use the USDC (FT)
            token to purchase AFL Mint digital collectibles.
          </p>
          <p className="pb-3">
            Funds can be added with a credit card, Google Pay and Apple Pay for
            adding funds to your account. You can also transfer tokens to your
            Blocto wallet. Just make sure you are transferring from Flowchain
            USDC.
          </p>
          <p>
            To swap tokens within your Blocto wallet:{' '}
            <a
              href="https://app.increment.fi/swap"
              target="_blank"
              rel="noreferrer"
              className="underline hover:no-underline"
            >
              https://app.increment.fi/swap
            </a>
            .
          </p>
          <p className="italic">
            *Depending on your financial institution, there may be additional
            rules and regulations with token and digital collectible purchases.
            Please contact your bank for additional information.
          </p>
        </div>
      )
    },
    {
      question: 'Is there a Blocto Wallet app?',
      answer: (
        <div>
          <p className="pb-3">
            Yes, there is a Blocto wallet app. Blocto wallet app is available
            for both ios and android. You can download the app for your
            respective device{' '}
            <a
              href="https://blocto.io/download"
              target="_blank"
              rel="noreferrer"
              className="underline hover:no-underline"
            >
              here
            </a>
            .
          </p>
          <p className="pb-3">
            You will need to download the Blocto wallet app to create an
            account.
          </p>
          <p>
            Within the Blocto wallet app you can access AFL Mint from the Blocto
            wallet app browser. You can also connect your Blocto wallet directly
            from AFL Mint website.
          </p>
        </div>
      )
    },
    {
      question: 'Where can I learn more about Blocto Wallet?',
      answer: (
        <div>
          <p>
            You can learn about the Blocto wallet by visiting{' '}
            <a
              href="https://blocto.io/"
              target="_blank"
              rel="noreferrer"
              className="underline hover:no-underline"
            >
              https://blocto.io/
            </a>
            .
          </p>
        </div>
      )
    }
  ],
  walletTransfer: [
    {
      question: 'What will be transferred?',
      answer: (
        <div>
          <p className="pb-3">
            Once you have your new Magic wallet, transferring funds and
            collectibles is entirely up to you. With a new wallet, it makes
            sense having all your AFL Mint collectibles stored in one place,
            Magic wallet!
          </p>
          <p className="pb-3">
            When you go through the transfer process you will be able to
            transfer both funds and collectibles from one wallet to the other.
            Just make sure both wallets are connected by clicking the toggle
            button on the wallets. You can transfer 250-300 collectibles in one
            go for your convenience.
          </p>
          <p>
            For example, If you had 20 collectibles after the transfer you will
            have 20 collectibles in your wallet of transfer. If you had 50 USDC
            tokens in your wallet and then completed the transfer process you
            would have 50 USDC in the wallet that you transferred it into.
          </p>
        </div>
      )
    },
    {
      question: 'Does it cost anything to transfer?',
      answer: (
        <div>
          <p>
            To you the account owner no you don’t pay anything for transferring.
            The wallets Magic & Blocto will cover transfer fees in the
            background.
          </p>
        </div>
      )
    },
    {
      question: 'Why should I transfer?',
      answer: (
        <div>
          <p>
            You don’t need to transfer if you do not want to. The option to
            transfer is your choice. We are introducing a new wallet for our
            community to use, people will want to have the freedom to move the
            collectibles from wallet to wallet.
          </p>
        </div>
      )
    },
    {
      question: 'Will my Trophy Cabinet change?',
      answer: `No it won't change. Changes that will impact you are if you log in with the wallet that you transferred items from, this might cause you to gasp out loud seeing an empty Trophy Cabinet. Take a deep breath and sign in with the other wallet and make sure that all of your items are there. Cosmetically nothing changes outside of the number of collectibles stored in each wallet.`
    },
    {
      question: 'Will my marketplace listings change?',
      answer: `You will not be able to transfer any items that are listed for sale. To transfer these items you will need to delist them from the marketplace, or leave them in the wallet that you are transferring from.`
    },
    {
      question: 'Can I transfer only selected collectibles?',
      answer: (
        <div>
          <p className="pb-3">No. Not at the moment.</p>
          <p className="pb-3">
            For smaller collections you can transfer everything not listed for
            sale in one transfer.
          </p>
          <p>
            For larger collections you will need to transfer a couple of times.
            You can transfer 250-300 items at once.
          </p>
        </div>
      )
    },
    {
      question: 'Can I transfer from an account I’m not connected to?',
      answer: `No, you need to connect to the account you want to transfer from. It's best to sign into your wallets before you start the transfer process, this way you have everything logged in and ready to use.`
    }
  ],
  mintGuide: [
    {
      question: 'Don’t forget to claim your FREE team badge',
      answer:
        'If you’re new to AFL Mint create your AFL iD account and then a Magic wallet. Your team of choice badge should now be in your Trophy Cabinet. As an existing user with a Blocto wallet, make sure your team of preference is filled in in your AFL iD account and then create a Magic wallet to receive your team badge.',
      ctaLink: '/learn/how-to-get-started',
      ctaText: 'Get Started'
    },
    {
      question: 'Discord community',
      answer: (
        <div>
          <p className="pb-4">
            Think of Discord as a big chat room. We have a community of over
            5000 people who love to talk about all things AFL. Join us for a
            chat, early AFL Mint information and a chance to win prizes such as
            packs and merchandise. We run quiz’s, challenges and polls
            regularly!
          </p>
          <a
            href={'https://discord.gg/aflmintofficial'}
            className="pr-1 ml-auto lg:pr-6"
            target="_blank"
            rel="noreferrer"
          >
            <button className="font-bold text-blue3 sm:text-lg ">
              Join the community <ChevronBtn sx={{ margin: '0 0 8px 0px' }} />
            </button>
          </a>
        </div>
      )
    },
    {
      question: 'There are many different ways to collect',
      answer:
        'Some users collect everything that exists, others collect moments from only their team of support. Some people simply collect the players they like to watch or big moments from big games. Lastly people collect rare serials. There are many ways to collect, find what’s right for you and it will be a much more enjoyable experience.',
      ctaLink: '/marketplace',
      ctaText: 'Marketplace'
    },
    {
      question: 'Marketplace filters',
      answer:
        'Use the filters on the left and top right to help find what you are looking for. The top right contains sorting based on date created, price and alphabetical order for team, last name and moment title. Whilst on the left you can narrow down the moments that are or are not for sale, within a price range, tier, team, which collection a moment is from, the type of moment, the stadium, which league (AFL or AFLW) or even by individual player.',
      ctaLink: '/marketplace',
      ctaText: 'Marketplace'
    },
    {
      question: 'Special serial numbers',
      answer: (
        <ul className="list-disc pl-6">
          <li className="pb-3">
            The #1 serial of any moment usually commands a high price and can be
            many times the price of most other serials of that moment.
          </li>
          <li className="pb-3">
            The jumper number (e.g. Lance Franklin wears #23, the #23 serial
            matches) also normally commands a price similar to the #1 serial. If
            you’re unsure of the player number it’s within the moment on the
            card so make sure you check the lower serials you have. It also has
            the jumper number badge.
          </li>
          <li className="pb-3">
            Perfect serials — e.g. #500/500 — are often valued a little higher
            than others.
          </li>
          <li className="pb-3">
            Others — sequential (#123), palindromes (#313), #888 can have a
            little more meaning to some collectors.
          </li>
        </ul>
      )
    },
    {
      question: 'Badges',
      answer:
        'Badges show a rare feat by a player. This helps users identify more significant moments from a season or player and in some instances can demand a higher price on the market place.'
    },
    {
      question: 'Challenges',
      answer: (
        <div>
          <p className="pb-4">
            Challenges are currently run via our discord server. They involve
            collecting certain moments during a specified time period. Users who
            successfully own these moments then qualify for a prize. These can
            really help grow your collection quickly. Look for players who seem
            to always be close to qualifying in a challenge as they will give
            you the best chance at winning multiple challenges as we run them
            weekly during the AFL season.
          </p>
          <a
            href={'https://discord.gg/aflmintofficial'}
            className="pr-1 ml-auto lg:pr-6"
            target="_blank"
            rel="noreferrer"
          >
            <button className="font-bold text-blue3 sm:text-lg ">
              Join Discord <ChevronBtn sx={{ margin: '0 0 8px 0px' }} />
            </button>
          </a>
        </div>
      )
    },
    {
      question: 'Sets',
      answer:
        'Head into your Trophy Cabinet and navigate to the ‘sets’ tab. Here you can see how many of each collection you own. If you collect an entire set the artwork will remain in full colour.',
      ctaLink: '/trophy-cabinet',
      ctaText: 'Trophy Cabinet'
    }
  ],
  rookies: [
    {
      question: 'How it works.',
      answer: (
        <div>
          <p className="pb-4 uppercase font-bold">Rip</p>
          <p className="pb-4">
            Player tokens (Commons) are found in packs. Rip your packs to see
            who you get!
          </p>
          <p className="pb-4 uppercase font-bold">Rank</p>
          <p className="pb-4">
            Which Rookies will reign? Track each player’s performance throughout
            the Toyota AFL Premiership season on our AFL Mint Rookies
            Leaderboard and see who earns rare status as Ovations and Deluxes.
          </p>
          <p className="pb-4 uppercase font-bold">Redeem</p>
          <p className="pb-4">
            Stack your player tokens and get ready to redeem them for epic debut
            game moments in our post-season exchange window.
          </p>
          <p className="pb-4">Exchange rules</p>
          <ul className="pb-4 pl-6 list-disc">
            <li>
              Ovation 5:1 - Exchange five player tokens for one Rookie game
              moment
            </li>
            <li>
              Deluxe 3:1 - Exchange three player tokens for one Rookie game
              moment
            </li>
            <li>
              Common 1:1 - Exchange one player token for one Rookie game moment
            </li>
          </ul>
          <p className="pb-4">
            *Final tiering will be in accordance with the AFL Mint Rookie
            Leaderboard.
          </p>
        </div>
      )
    },
    {
      question: 'Can I collect tokens and game moments?',
      answer:
        'Yes, all tokens and game moments will have newly minted, randomised serials, creating two different sets. This allows collectors two chances at chasing serials, and a fair chance for everyone who exchanges their tokens to be in the running to receive #1 and Jumper Number serials.'
    },
    {
      question: 'How will the collections be tiered?',
      answer: `All tokens will be Commons, which won’t receive any badges. This way, all have an equal chance of being revealed and a lack of badges won’t take away from the moments being the hero. Being minted at only 365, they’re quite rare. With the exchangeable moments, the Ovations and Deluxes will be awarded according to the best performances on our AFL Mint Rookies Leaderboard, with one additional Ovation reserved for the best Rookie moment of the season. Positions 1-2 will be awarded Ovation tier. Positions 3-7 on the leaderboard will earn Deluxe tier. These moments will receive any relevant badges.`
    },
    {
      question: 'How do you determine the ranking of the Rookies?',
      answer: `We've created the AFL Mint Rookies Leaderboard where we’ll track the following six categories: disposals, clearances, goals, score involvement, tackles and intercepts, awarding points 10-1 based on the rookies total in each category, after each round. These categories make it fair for all playing styles and positions. We’ll then tally them up to form an overall score attributed each week. In the event that two players score a tie for a certain category, the points will be split. The leaderboard will be updated after each round of footy.`
    },
    {
      question: 'When can I exchange my player tokens for a moment?',
      answer: `Post home and away season in October 2024. Stay tuned for dates and times.`
    },
    {
      question:
        'How do I get more player tokens for an exchange if I didn’t get them in my packs?',
      answer: `Head to the AFL Mint Marketplace to score more of the player tokens you need.`
    },
    {
      question:
        'What if my Rookie doesn’t debut in the 2024 Toyota AFL Premiership season?',
      answer: `If a Rookie doesn’t play in 2024, their tokens will be eligible to be swapped in the 2025 season exchange period, if they play then. This moment will be a Common. If they don’t play for two seasons, they will not be eligible for a game moment, and will live on as a forever player token, which may be pretty unique in its own right.`
    }
  ]
}
