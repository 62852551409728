import { Box, LinearProgress, Typography } from '@mui/material'
import Spinner from './Spinner'
import { useEffect, useState } from 'react'
interface TransactionDetailsProps {
  id: string
  message?: string
  status?: number
}

const statusString = (status: number | undefined): string => {
  switch (status) {
    case 0:
      return 'Unknown'
    case 1:
      return 'Pending - Awaiting Finalization'
    case 2:
      return 'Finalized - Awaiting Execution'
    case 3:
      return 'Executed - Awaiting Sealing'
    case 4:
      return 'Sealed - Transaction Complete. At this point the transaction result has been committed to the blockchain.'
    case 5:
      return 'Expired'
    default:
      return ''
  }
}

const statusToPercentage = (
  status: number | undefined,
  prevProgress: number
): number => {
  if (status === undefined || status <= prevProgress) return prevProgress
  switch (status) {
    case 0:
      return 0
    case 1:
      return 25
    case 2:
      return 50
    case 3:
      return 75
    case 4:
      return 100
    case 5:
      return 100
    default:
      return 0
  }
}

export default function TransactionDetails(props: TransactionDetailsProps) {
  const [prevProgress, setPrevProgress] = useState(0)

  useEffect(() => {
    setPrevProgress((prevProgress) =>
      statusToPercentage(props?.status, prevProgress)
    )
  }, [props.status])

  return (
    <div className="flex flex-col justify-center items-center py-2 px-4 h-auto text-white bg-gradient-to-r from-gradient1 to-gradient2 rounded">
      <div className="flex items-center text-sm truncate whitespace-nowrap">
        <Spinner />
        <p className="ml-5 font-podium49 text-base tracking-widest uppercase truncate">
          {props?.message || 'Transaction in progress'}
        </p>
      </div>
      <div className="w-full">
        <LinearProgress
          variant="determinate"
          value={statusToPercentage(props?.status, prevProgress)}
        />
      </div>
    </div>
  )
}
