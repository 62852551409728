export interface SpinnerProps {
  centered?: boolean
  isLarge?: boolean
  className?: string
}

export default function Spinner({
  centered,
  isLarge,
  className
}: SpinnerProps) {
  return (
    <div
      className={`${centered && '!w-[40px] '} ${className} ${
        isLarge && '!w-[76px] my-8'
      } lds-ring`}
    >
      <div className={`${isLarge && '!w-16 !h-16'}`}></div>
      <div className={`${isLarge && '!w-16 !h-16'}`}></div>
      <div className={`${isLarge && '!w-16 !h-16'}`}></div>
      <div className={`${isLarge && '!w-16 !h-16'}`}></div>
    </div>
  )
}
